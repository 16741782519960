import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableSwap } from '../../types';

export const COINS = {
  KLAY: {
    network: Network.BAOBAB,
    address: '0x0',
    name: 'KLAYTN',
  },
  BORA: {
    network: Network.BAOBAB,
    address: '0x19e822d63d2f6a7fdf0f9644471903b09585f51a',
    name: 'BORA',
  },
  WKLAY: {
    network: Network.BAOBAB,
    address: '0x60cd78c3ede4d891455ceaecfa97eecd819209cf',
    name: 'WKLAY',
  },
  KSP: {
    network: Network.BAOBAB,
    address: '0xd2e72adcdd82e687158541fe196d53ed60caac09',
    name: 'KSP',
  },
  KUSDT: {
    network: Network.BAOBAB,
    address: '0x5Fd419D7D91E7D90f3a82c685ED9737bC188C23a',
    name: 'KUSDT',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.KLAY,
    coin2: COINS.BORA,
    pairContractAddress: '0x41d559da358cf3b626a6ce3e162ba30f40f7f45c',
    routerContractAddress: '0xb1c4c22fee13da89e8d983227d9dc6314e29894a',
    // Previous used gas for a swap tx: 157,792
    // https://scope.boraportal.com/tx/0x510939ae2dee64a63e6d50a176baa2f88b160dd2e62cb23e2df4a0b1542c6161
    swapTxGasUsed: BigNumber.from(200 * 1000),
  },
  {
    coin1: COINS.KLAY,
    coin2: COINS.KSP,
    pairContractAddress: '0x599c78df0c1000db4f42b0a13e5fd65bd5dbeb5e',
    routerContractAddress: '0xd2e72adcdd82e687158541fe196d53ed60caac09',
    swapTxGasUsed: BigNumber.from(500 * 1000),
  },
  {
    coin1: COINS.KLAY,
    coin2: COINS.KUSDT,
    pairContractAddress: '0xa75754820528a7eef9ec13dd65eeb30902c67b57',
    routerContractAddress: '0xd2e72adcdd82e687158541fe196d53ed60caac09',
    swapTxGasUsed: BigNumber.from(500 * 1000),
  },
];
