import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { BoraTBoraQuote } from '../../quotes/boratbora/BoraTBoraQuote';
import { UniswapV2Quote } from '../../quotes/uniswapv2/UniswapV2Quote';
import { AvailableSwap } from '../../types';

export const COINS = {
  TBORA: {
    network: Network.BORA,
    address: '0x797115bcdbD85DC865222724eD67d473CE168962',
    name: 'TBORA',
  },
  BSLT: {
    network: Network.BORA,
    address: '0xcFdbf6a1f765C295d8D557E841C1304019318525',
    name: 'BSLT',
  },
  BID: {
    network: Network.BORA,
    address: '0x7769cf946c011BB3A22d20ba6bF7f0bC419c722f',
    name: 'BID',
  },
  BORA: {
    network: Network.BORA,
    address: '0x0',
    name: 'BORA',
  },
  WBORA: {
    network: Network.BORA,
    address: '0x18b9c43f1e8e67db3a1ec237ff8422c4366e6be6',
    name: 'WBORA',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.TBORA,
    coin2: COINS.BORA,
    routerContractAddress: '0xc5eff6924b28fb32fb59e349bc579d4c2169487b',
    swapTxGasUsed: BigNumber.from(200 * 1000),
    quote: BoraTBoraQuote,
  },
  {
    coin1: COINS.BORA,
    coin2: COINS.BSLT,
    routerContractAddress: '0x02ffded9357e9f6fd7f7b10e5b52913ab5f3ae1d',
    swapTxGasUsed: BigNumber.from(500 * 1000),
    quote: UniswapV2Quote,
  },
  {
    coin1: COINS.BORA,
    coin2: COINS.BID,
    routerContractAddress: '0x02ffded9357e9f6fd7f7b10e5b52913ab5f3ae1d',
    swapTxGasUsed: BigNumber.from(500 * 1000),
    quote: UniswapV2Quote,
  },
];
