import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

export type NetworkType = 'mainnet' | 'testnet';

export type Address = string;

export type Coin = {
  network: Network;
  address: Address;
  // for debugging purpose
  name?: string;
};

export function coinEquals(coin1: Coin, coin2: Coin): boolean {
  return (
    coin1.network === coin2.network && coin1.address.toLowerCase() === coin2.address.toLowerCase()
  );
}

export type AvailableConvert = {
  from: Coin;
  to: Coin;
  convertContractAddress: Address;
  toBlockchainContractAddress: Address;
  gasUsed: BigNumber;
  quote: any;
};

/**
 * TO_CHAIN_PENDING: to chain tx is not mined
 * FINISHED: to chain tx is mined
 */
export type ConvertStatus = {
  status: 'TO_CHAIN_PENDING' | 'FINISHED' | 'FAILED';
  toChain: {
    txHash: string;
    blockNumber: number;
    blockHash: string;
  } | null;
};

export type CoinAmount = {
  coin: Coin;
  amount: BigNumber;
};

export enum ConvertType {
  BORA_PORTAL = 'BORA_PORTAL', // BORA <-> KLAYTN
  BORA_PORTAL_V3 = 'BORA_PORTAL_V3', // BORA <-> KLAYTN
  PURPLE_BRIDGE = 'PURPLE_BRIDGE', // BORA <-> POLYGON
}
