import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import { ERC20_APPROVE_ABI, PURPLE_BRIDGE_ABI } from '@haechi-labs/shared/constants/abi';
import { BigNumber, constants, ethers } from 'ethers';

import { APPROVE_GAS_USED } from '../../constant';
import { Quote, SerializedQuote } from '../../Quote';
import {
  Address,
  AvailableConvert,
  Coin,
  CoinAmount,
  ConvertStatus,
  ConvertType,
  NetworkType,
} from '../../types';
import { checkTokenApproval, getPlatformCoin } from '../../utils';

interface QuoteData {
  isApprovalNeeded: boolean;
  toBlockchainRecentBlockNumber: number;
  convertFee: CoinAmount;
  convertTxFee: CoinAmount;
  approveTxFee: CoinAmount;
}

const BURN_EVENT_HASH = '0xb3d2c641447abd5dce6e39308cf9bf533d8484617c8c62cd9b89551d74759dcd';
const LOCK_EVENT_HASH = '0xd9f8c1c030380aaa3c47e8cd3e80b30ff114b5842ca6a6e77ee43be4539801d3';

/**
 * tBora(Bora) <-> pBora(Polygon)
 */
export class PurpleBridgeQuote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly fromProvider: ethers.providers.Provider;
  private readonly toProvider: ethers.providers.Provider;

  public readonly walletAddress: Address;
  public readonly fromAmount: BigNumber;
  public readonly from: Coin;
  public readonly to: Coin;
  public readonly pair: AvailableConvert;

  private constructor(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    data: QuoteData,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ) {
    this.networkType = networkType;
    this.data = data;
    this.walletAddress = walletAddress;
    this.fromProvider = fromProvider;
    this.toProvider = toProvider;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.pair = pair;
  }

  /**
   * Quote 객체를 만들고 필요한 정보를 얻어옴
   */
  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const [isApprovalNeeded, toBlockchainRecentBlockNumber, convertFeeAmount, gasPrice] =
      await Promise.all([
        checkTokenApproval(
          from.address,
          walletAddress,
          pair.convertContractAddress,
          fromAmount,
          fromProvider
        ),
        toProvider.getBlockNumber(),
        this.calculateFee({ fromAmount }),
        fromProvider.getGasPrice(),
      ]);

    const quoteData = {
      isApprovalNeeded,
      toBlockchainRecentBlockNumber,
      convertFee: {
        coin: from,
        amount: convertFeeAmount,
      },
      convertTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(pair.gasUsed),
      },
      approveTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };
    return new PurpleBridgeQuote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  buildApproveTx(): TransactionRequest {
    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      this.pair.convertContractAddress,
      ethers.constants.MaxUint256,
    ]);
    return {
      to: this.from.address,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  /**
   * from blockchain 의 walletAddress에서 to blockchain의 walletAddress로
   */
  buildConvertTx(): TransactionRequest {
    if (this.fromAmount.lt(this.data.convertFee.amount)) {
      throw new Error('fee is larger than from amount');
    }

    const isToBora =
      networkToBlockchain(this.from.network) === Blockchain.POLYGON &&
      networkToBlockchain(this.to.network) === Blockchain.BORA;

    const convertInterface = new ethers.utils.Interface(PURPLE_BRIDGE_ABI);
    const functionData = convertInterface.encodeFunctionData(
      isToBora ? 'burnAndRelease' : 'lockAndMint',
      [this.walletAddress, this.fromAmount]
    );

    return {
      to: this.pair.convertContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: this.pair.gasUsed,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  async checkStatus(convertTxHash: string): Promise<ConvertStatus> {
    const isToBora =
      networkToBlockchain(this.from.network) === Blockchain.POLYGON &&
      networkToBlockchain(this.to.network) === Blockchain.BORA;

    const convertTxReceipt = await this.fromProvider.getTransactionReceipt(convertTxHash);

    if (!convertTxReceipt) {
      return {
        status: 'TO_CHAIN_PENDING',
        toChain: null,
      };
    }

    if (convertTxReceipt?.status === 0) {
      return {
        status: 'FAILED',
        toChain: null,
      };
    }

    const fromEvent = convertTxReceipt.logs.find(
      (log) =>
        log.topics[0].toLowerCase() === (isToBora ? BURN_EVENT_HASH : LOCK_EVENT_HASH).toLowerCase()
    );

    if (!fromEvent) {
      throw new Error('');
    }

    const uniqueKey = fromEvent.topics[1];
    const toContract = new ethers.Contract(
      this.pair.toBlockchainContractAddress,
      PURPLE_BRIDGE_ABI,
      this.toProvider
    );
    const args = {
      uniqueKey: uniqueKey,
      srcChainId: null,
      destUser: this.walletAddress,
      amount: null,
      fee: null,
    };

    const filter = toContract.filters[isToBora ? 'Release' : 'Mint'](
      args.uniqueKey,
      args.srcChainId,
      args.destUser,
      args.amount,
      args.fee
    );

    const events = await toContract.queryFilter(filter, this.data.toBlockchainRecentBlockNumber);

    if (events.length > 0) {
      return {
        status: 'FINISHED',
        toChain: {
          txHash: events[0].transactionHash,
          blockNumber: events[0].blockNumber,
          blockHash: events[0].blockHash,
        },
      };
    }

    return {
      status: 'TO_CHAIN_PENDING',
      toChain: null,
    };
  }

  getFee(): { convertFee: CoinAmount; approveTxFee: CoinAmount; convertTxFee: CoinAmount } {
    return {
      convertFee: this.data.convertFee,
      approveTxFee: this.data.approveTxFee,
      convertTxFee: this.data.convertTxFee,
    };
  }

  getTargetAmount(): BigNumber {
    return this.fromAmount.sub(this.data.convertFee.amount);
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  refresh(): Promise<Quote> {
    return PurpleBridgeQuote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.fromProvider,
      this.toProvider,
      this.pair
    );
  }

  async serialize(): Promise<SerializedQuote> {
    const latestBlockNumber = await this.toProvider.getBlock('latest');
    return {
      fromBlockchainNetwork: this.from.network,
      toBlockchainNetwork: this.to.network,
      fromCoinAddress: this.from.address,
      toCoinAddress: this.to.address,
      fromAmount: this.fromAmount.toHexString(),
      toAmount: this.getTargetAmount().toHexString(),
      fromAddress: this.walletAddress,
      toAddress: this.walletAddress,
      // this may vary by platform. If new type implemented, fix this too.
      type: ConvertType.PURPLE_BRIDGE,
      toBlockchainRecentBlockNumber: latestBlockNumber.number,
    };
  }

  static async deserialize(
    data: SerializedQuote,
    networkType: NetworkType,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const fromNetwork = data.fromBlockchainNetwork.toUpperCase() as Network;
    const toNetwork = data.toBlockchainNetwork.toUpperCase() as Network;

    if (
      ![Blockchain.BORA, Blockchain.POLYGON].includes(networkToBlockchain(fromNetwork)) ||
      ![Blockchain.BORA, Blockchain.POLYGON].includes(networkToBlockchain(toNetwork))
    ) {
      throw new Error('invalid blockchain');
    }

    const fromAmount = ethers.BigNumber.from(data.fromAmount);

    const fromCoin = {
      network: fromNetwork,
      address: data.fromCoinAddress,
    };
    const toCoin = {
      network: toNetwork,
      address: data.toCoinAddress,
    };

    const quoteData = {
      isApprovalNeeded: false,
      toBlockchainRecentBlockNumber: data.toBlockchainRecentBlockNumber,
      convertFee: {
        coin: fromCoin,
        amount: constants.Zero,
      },
      convertTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
      approveTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
    };

    return new PurpleBridgeQuote(
      networkType,
      data.fromAddress,
      fromCoin,
      toCoin,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  static async calculateFee(data: { fromAmount: BigNumber }): Promise<BigNumber> {
    const base = ethers.utils.parseUnits('250', 18);
    if (data.fromAmount.lte(base)) {
      return BigNumber.from(ethers.utils.parseUnits('1.5', 18));
    }

    return data.fromAmount.mul(6).div(1000);
  }

  getConvertingTime() {
    return '3';
  }
}
