import { Network } from '@haechi-labs/face-types';

// TODO: find better place to locate this
// ref: https://haechilabs.slack.com/archives/C04HTNZH5D5/p1701743842199579

const nexonAppApiKeys = [
  // Nexon Dapp Key
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCsY-w2HRh6VwRfOw5P7pMvQzxf3Hz3dOurwCeV4AHmVWpxh-DfQx8aKL8394dJVy7x2geVeKR62rSl58HsFsr9utgvHb7PzZluV6DlDQJKrsHIjYiOSKVadMUgNHV40NgrzQggxuOY0MhJ599GWeXps_PPzWv7hsLircae1PnVOQIDAQAB',
  // https://haechilabs.slack.com/archives/C04ES5HTVRC/p1720086695253639?thread_ts=1717467968.110359&cid=C04ES5HTVRC
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCoeg7yUp-4lthxpK_q9oRoU6RdgrqYxF7JghsZ9rHxuxLmG2mDcT3l05ykfxLzxgi9iYu4BfH3cQJC8x5crnP_J-J4R_Bu3qvy4I-yyfV8Iv3FlrW1eSldaQGG3dMzvtOaFwV-BLKkamsax3s-zSXUBI3Tl1exxL9wHv1cB-5OgwIDAQAB',

  // allow for QA apps
  // ref: https://haechilabs.slack.com/archives/C03RPALDC3S/p1701765113196749?thread_ts=1701763773.998949&cid=C03RPALDC3S
  // PM Dapp key for QA
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCnh7y9sFxoFWwjP9Xlg7s9kXqfRGMusccEFr-LcvzyfPrFohvaKw0YLdMdb8trgK5M5VCEGWJ8p_sMSwb6i_MkQwv2s711uKxHAasUmDtX6eMy3eNKowdZo5nXraQpQd529c0jtZt5l07ryHHts3k4MF0W83GpaoWgjSLXhMEK8QIDAQAB',
  // PM Dapp key for PT -> PM 마이그레이션
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCYU6H6iw3iACEavvEXxrsZ-erPAB-oR6xngU8Mugw-y89umz77r1KJHJFnGG7jdjrHC211CUijwL4y8jpt_NCNefsAayFcBHOQElp-So_uaebTIBrkqEH4i3qkr0xJtH1HmNfsrcwWyE4sztRqmCLHG7KJDDsjXVsigsPqQwRKXwIDAQAB',
  // PT Dapp key for QA
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCqBmCzkl0ClfF_yuegA44dL5KGOq4BxtQlizFKFjh3VR2zIyHmp8Y6aFXhLmuJjiyRvZXi9K_dA0mEH458U9pf7iffGZOEa8fRELachZmd2-dZuI6mdL7XrNPY8XNPG4EcCtp3GVVefSr3dKEaTaBl6c0BUBWRBcpWLkX0cSe2bQIDAQAB',
  // ST Dapp key for QA
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCS23ncDS7x8nmTuK1FFN0EfYo0vo6xhTBMBNWVbQsufv60X8hv3-TbAQ3JIyMEhLo-c-31oYrvrQ0G2e9j8yvJYEUnLuE-PaABo0y3V5m9g_qdTB5p9eEfqZlDrcUl1zUr4W7rJwFwkTlAFSKOqVCPnm8ozmcMyyrEHgl2AbehrQIDAQAB',
  // PM sample dapp api key
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDFhGcETETTZVrysoeadS_HGjY3p_jxFkZMR3aE2Sy-d7kjn_WJLYd5f3voEK9mbmLvglt3SGLw08xh_mVFD3rP8QoCNmjkyCbMIeszpCtudAAq1Ar37ZWCKQArWlhXB9EtTU9e3E4FBcBW9vqaPxiT8sk2MBFkCeinNSDHTdQ2uwIDAQAB',
];

const nexonPsmNetworks = new Set([
  // TODO: PSM 삭제
  Network.LITH,
  Network.PSM,
  Network.PSM_TESTNET,
  Network.PSM_TESTNET_DEV,
  Network.PSM_TESTNET_TEST,
]);

const nexonAsmNetworks = new Set([
  // Network.ASM,
  Network.LITH,
  Network.ASM_QA,
  Network.ASM_TEST,
  Network.ASM_DEV,
  Network.ASM_STAGE,
]);

function isNexonByApiKey(apiKey: string) {
  return nexonAppApiKeys.includes(apiKey);
}

export function filterNexonNetworks(apiKey: string, previousNetworks: Set<Network>) {
  if (isNexonByApiKey(apiKey)) {
    return previousNetworks;
  }
  const nextNetworks = new Set(previousNetworks);
  for (const blockchain of nexonPsmNetworks) {
    nextNetworks.delete(blockchain);
  }
  for (const blockchain of nexonAsmNetworks) {
    nextNetworks.delete(blockchain);
  }
  return nextNetworks;
}

export function filterNexonNetworksWithNetworkGetter<T>(
  apiKey: string,
  items: ReadonlyArray<T>,
  networkGetter: (arg: T) => Network
) {
  if (isNexonByApiKey(apiKey)) {
    return items;
  }
  const filteredItems: Array<T> = [];
  for (const item of items) {
    const network = networkGetter(item);
    if (nexonPsmNetworks.has(network) || nexonAsmNetworks.has(network)) {
      continue;
    }
    filteredItems.push(item);
  }
  return filteredItems;
}
