import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableSwap } from '../../types';

export const COINS = {
  KLAY: {
    network: Network.KLAYTN,
    address: '0x0',
    name: 'KLAYTN',
  },
  BORA: {
    network: Network.KLAYTN,
    address: '0x02cbe46fb8a1f579254a9b485788f2d86cad51aa',
    name: 'BORA',
  },
  WKLAY: {
    network: Network.KLAYTN,
    address: '0xe4f05a66ec68b54a58b17c22107b02e0232cc817',
    name: 'WKLAY',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.KLAY,
    coin2: COINS.BORA,
    pairContractAddress: '0xbbca9b2d17987ace3e98e376931c540270ce71bb',
    routerContractAddress: '0xc6a2ad8cc6e4a7e08fc37cc5954be07d499e7654',
    swapTxGasUsed: BigNumber.from(500 * 1000),
  },
];
