import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import { BORA3_CONVERT_ABI, ERC20_APPROVE_ABI } from '@haechi-labs/shared/constants/abi';
import { BigNumber, constants, ethers } from 'ethers';
import { parseEther } from 'ethers/lib/utils';

import { APPROVE_GAS_USED } from '../../constant';
import { Quote, SerializedQuote } from '../../Quote';
import {
  Address,
  AvailableConvert,
  Coin,
  CoinAmount,
  ConvertStatus,
  ConvertType,
  NetworkType,
} from '../../types';
import { checkTokenApproval, getPlatformCoin, isPlatformCoinAddress } from '../../utils';

interface QuoteData {
  isApprovalNeeded: boolean;
  toBlockchainRecentBlockNumber: number;
  convertFee: CoinAmount;
  convertTxFee: CoinAmount;
  approveTxFee: CoinAmount;
}

/**
 * BORA(b) <-> Bora(k), pBORA
 */
export class BoraPortalV3Quote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly fromProvider: ethers.providers.Provider;
  private readonly toProvider: ethers.providers.Provider;

  public readonly walletAddress: Address;
  public readonly fromAmount: BigNumber;
  public readonly from: Coin;
  public readonly to: Coin;
  public readonly pair: AvailableConvert;
  public readonly WBORA_TOKEN_ADDRESS: string;

  private constructor(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    data: QuoteData,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ) {
    this.networkType = networkType;
    this.data = data;
    this.walletAddress = walletAddress;
    this.fromProvider = fromProvider;
    this.toProvider = toProvider;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.pair = pair;
    this.WBORA_TOKEN_ADDRESS =
      networkType === 'testnet'
        ? '0x5e63a7b049a5adaea5decc36c3e46c33a8a6c663'
        : // https://haechilabs.slack.com/archives/C044B9N6HKJ/p1718082379837149?thread_ts=1718062127.847339&cid=C044B9N6HKJ
          '0x62e2a41cd7b3104486274e5ae02045080d99298f';
  }

  /**
   * Quote 객체를 만들고 필요한 정보를 얻어옴
   */
  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const [isApprovalNeeded, toBlockchainRecentBlockNumber, convertFeeAmount, gasPrice] =
      await Promise.all([
        checkTokenApproval(
          from.address,
          walletAddress,
          pair.convertContractAddress,
          fromAmount,
          fromProvider
        ),
        toProvider.getBlockNumber(),
        this.calculateFee(fromAmount, from, to),
        fromProvider.getGasPrice(),
      ]);

    const quoteData = {
      isApprovalNeeded,
      toBlockchainRecentBlockNumber,
      convertFee: {
        coin: from,
        amount: convertFeeAmount,
      },
      convertTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(pair.gasUsed),
      },
      approveTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };

    return new BoraPortalV3Quote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  // BORA 3.0 수수료 정률 적용. fromAmount === toAmount 이므로 fromAmount의 0.1%로 계산.
  // 스레드: https://haechilabs.slack.com/archives/C044B9N6HKJ/p1712545142120959?thread_ts=1709704572.382629&cid=C044B9N6HKJ
  //
  // 0.1% + target blockchain fee 사용. API 호출해야하지만 현재는 고정값으로 계산
  static async calculateFee(fromAmount: BigNumber, from: Coin, to: Coin) {
    const env = getEnv();
    let hostname;
    if (env === 'local') {
      hostname = 'http://localhost';
    } else if (env === 'stage') {
      hostname = 'https://api.stage-test.facewallet.xyz';
    } else if (env === 'prod') {
      hostname = 'https://api.facewallet.xyz';
    } else {
      throw new Error('invalid env ' + env);
    }

    let fromBlockchain;
    if (from.network === Network.BORA_TESTNET || from.network === Network.BORA) {
      fromBlockchain = 'BORA';
    } else if (from.network === Network.BAOBAB || from.network === Network.KLAYTN) {
      fromBlockchain = 'KLAYTN';
    } else if (from.network === Network.AMOY || from.network === Network.POLYGON) {
      fromBlockchain = 'POLYGON';
    } else {
      throw new Error('invalid from network: ' + from.network);
    }

    let toBlockchain;
    if (to.network === Network.BORA_TESTNET || to.network === Network.BORA) {
      toBlockchain = 'BORA';
    } else if (to.network === Network.BAOBAB || to.network === Network.KLAYTN) {
      toBlockchain = 'KLAYTN';
    } else if (to.network === Network.AMOY || to.network === Network.POLYGON) {
      toBlockchain = 'POLYGON';
    } else {
      throw new Error('invalid to network: ' + to.network);
    }

    let network;
    if (
      from.network === Network.BORA_TESTNET ||
      from.network === Network.BAOBAB ||
      from.network === Network.AMOY
    ) {
      network = 'bora_testnet';
    } else if (
      from.network === Network.BORA ||
      from.network === Network.KLAYTN ||
      from.network === Network.POLYGON
    ) {
      network = 'bora';
    } else {
      throw new Error('invalid from network: ' + from.network);
    }

    const url = `${hostname}/v1/bora/portal/convert-fee?fromBlockchain=${fromBlockchain}&toBlockchain=${toBlockchain}&fromAmount=${fromAmount}&network=${network}`;

    const result = await fetch(url);
    const jsonResult = await result.json();
    let toAmount;
    try {
      toAmount = BigNumber.from(jsonResult.toAmount);
    } catch (e) {
      console.error('failed to parse jsonResult', jsonResult);
      throw e;
    }

    return fromAmount.sub(toAmount);
  }

  buildApproveTx(): TransactionRequest {
    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      // arguments: [spender, value]
      this.pair.convertContractAddress, // spender: from 컨트랙트 주소
      ethers.constants.MaxUint256, // value: MaxUint256 고정. 원래는 fromAmount를 넣어야하나, facewallet 정책으로 MaxUint256을 넣어서 한번 approve로 allowance를 조회해서 여러번 트랜잭션을 날릴 수 있음
    ]);
    return {
      from: this.walletAddress,
      to: this.from.address,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED, // 80000
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  /**
   * from blockchain 의 walletAddress에서 to blockchain의 walletAddress로
   */
  buildConvertTx(): TransactionRequest {
    if (this.fromAmount.lt(this.data.convertFee.amount)) {
      throw new Error('fee is larger than from amount');
    }

    const isFromNativeCoin =
      this.from.network === Network.BORA_TESTNET || this.from.network === Network.BORA;

    const buildConvertTxValues = [
      // arguments: [tokenType, tokenAddress, from, to, tokenId, amount, targetChainId, extraData]
      isFromNativeCoin ? '0' : '20', // tokenType: from이 보라면 0, 아니면 20 고정(klaytn, polygon). erc20을 뜻하는 걸로 보임
      isFromNativeCoin ? this.WBORA_TOKEN_ADDRESS : this.from.address, // tokenAddress: from이 보라면 토큰 주소 고정, 아니면 from 토큰 주소(klaytn, polygon)
      this.walletAddress, // from: 내 지갑주소. from, to 주소 동일해야 함
      this.walletAddress, // to
      '0', // tokenId: 고정
      this.fromAmount, // amount
      this.getTargetChainId(), // targetChainId: 네트워크에 따라 다른 chainId 입력
      '0x00', // extraData: 고정
    ];
    const functionData = BORA3_CONVERT_ABI.encodeFunctionData(
      'requestRouterTransfer',
      buildConvertTxValues
    );
    return {
      from: this.walletAddress,
      to: this.pair.convertContractAddress,
      data: functionData,
      value: isPlatformCoinAddress(this.from.address) ? this.fromAmount : 0, // value: 네이티브 코인이면 amount 입력

      nonce: undefined,
      gasLimit: this.pair.gasUsed,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  // BORA 3.0 Convert 체인아이디 가져오기
  // TODO: 메인넷 chain id 추가 필요
  getTargetChainId() {
    switch (this.to.network) {
      case Network.BAOBAB:
        return 1001;
      case Network.BORA_TESTNET:
        return 99001;
      case Network.AMOY:
        return 80002;
      case Network.BORA:
        return 77001;
      case Network.KLAYTN:
        return 8217;
      case Network.POLYGON:
        return 137;
      default:
        throw new Error('invalid network: ' + this.to.network);
    }
  }

  // convert의 진행 상황을 얻어옵니다.
  async checkStatus(convertTxHash: string): Promise<ConvertStatus> {
    const toContract = new ethers.Contract(
      this.pair.toBlockchainContractAddress,
      BORA3_CONVERT_ABI,
      this.toProvider
    );
    const args = {
      requestTxHash: null,
      tokenType: null,
      from: this.walletAddress,
      to: this.walletAddress,
      tokenAddress: this.to.address,
      valueOrTokenId: null,
      handleNonce: null,
      lowerHandleNonce: null,
      extraData: null,
    };

    // arguments: [requestTxHash, tokenType, from, to, tokenAddress, valueOrTokenId, handleNonce, lowerHandleNonce, extraData]
    const filter = toContract.filters.HandleValueTransfer(
      args.requestTxHash,
      args.tokenType,
      null, // from: null 고정. 기존에는 args.from을 넣어줬으나 보라 3.0에서는 아래 에러 발생해서 null로 변경함
      // 에러: cannot filter non-indexed parameters; must be null (argument="contract.from", value="0x104f3E380951F29A5B3098790e5a86f7DDBd1A6a", code=INVALID_ARGUMENT, version=abi/5.7.0)
      args.to,
      args.tokenAddress === '0x0' ? this.WBORA_TOKEN_ADDRESS : args.tokenAddress, // tokenAddress: 0x0인 경우 WBORA의 컨트랙트 주소를 넣어야함
      args.valueOrTokenId,
      args.handleNonce,
      args.lowerHandleNonce,
      args.extraData
    );

    let events = await toContract.queryFilter(filter, this.data.toBlockchainRecentBlockNumber);
    events = events.filter(
      (ev) => ev.args?.requestTxHash.toLowerCase() === convertTxHash.toLowerCase()
    );
    if (events.length > 0) {
      return {
        status: 'FINISHED',
        toChain: {
          txHash: events[0].transactionHash,
          blockNumber: events[0].blockNumber,
          blockHash: events[0].blockHash,
        },
      };
    } else {
      const fromReceipt = await this.fromProvider.getTransactionReceipt(convertTxHash);
      if (fromReceipt?.status === 0) {
        return {
          status: 'FAILED',
          toChain: null,
        };
      }

      return {
        status: 'TO_CHAIN_PENDING',
        toChain: null,
      };
    }
  }

  getFee(): { convertFee: CoinAmount; approveTxFee: CoinAmount; convertTxFee: CoinAmount } {
    return {
      convertFee: this.data.convertFee,
      approveTxFee: this.data.approveTxFee,
      convertTxFee: this.data.convertTxFee,
    };
  }

  getTargetAmount(): BigNumber {
    return this.fromAmount.sub(this.data.convertFee.amount);
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  refresh(): Promise<Quote> {
    return BoraPortalV3Quote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.fromProvider,
      this.toProvider,
      this.pair
    );
  }

  async serialize(): Promise<SerializedQuote> {
    const latestBlockNumber = await this.toProvider.getBlock('latest');
    return {
      fromBlockchainNetwork: this.from.network,
      toBlockchainNetwork: this.to.network,
      fromCoinAddress: this.from.address,
      toCoinAddress: this.to.address,
      fromAmount: this.fromAmount.toHexString(),
      toAmount: this.getTargetAmount().toHexString(),
      fromAddress: this.walletAddress,
      toAddress: this.walletAddress,
      type: ConvertType.BORA_PORTAL_V3, // this may vary by platform. If new type implemented, fix this too.
      toBlockchainRecentBlockNumber: latestBlockNumber.number,
    };
  }

  static async deserialize(
    data: SerializedQuote,
    networkType: NetworkType,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const fromNetwork = data.fromBlockchainNetwork.toUpperCase() as Network;
    const toNetwork = data.toBlockchainNetwork.toUpperCase() as Network;
    const fromBlockchain = networkToBlockchain(fromNetwork);
    const toBlockchain = networkToBlockchain(toNetwork);

    const availableBlockchains = [Blockchain.BORA, Blockchain.KLAYTN, Blockchain.POLYGON];
    if (
      !availableBlockchains.includes(fromBlockchain) ||
      !availableBlockchains.includes(toBlockchain)
    ) {
      throw new Error(`invalid blockchain: ${fromBlockchain}, ${toBlockchain}`);
    }

    const fromAmount = ethers.BigNumber.from(data.fromAmount);

    const fromCoin = {
      network: fromNetwork,
      address: data.fromCoinAddress,
    };
    const toCoin = {
      network: toNetwork,
      address: data.toCoinAddress,
    };

    const quoteData = {
      isApprovalNeeded: false,
      toBlockchainRecentBlockNumber: data.toBlockchainRecentBlockNumber,
      convertFee: {
        coin: fromCoin,
        amount: constants.Zero,
      },
      convertTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
      approveTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
    };
    return new BoraPortalV3Quote(
      networkType,
      data.fromAddress,
      fromCoin,
      toCoin,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  getConvertingTime() {
    return '1';
  }
}

export function getEnv() {
  const hostname = window.location.hostname;

  switch (hostname) {
    case 'app.stage-test.facewallet.xyz':
    case 'app.stage.facewallet.xyz':
      return 'stage';
    case 'app.test.facewallet.xyz':
    case 'app.facewallet.xyz':
      return 'prod';
    case 'localhost':
      return 'local';
  }

  // multi stage
  if (hostname.endsWith('.facewallet-test.xyz')) {
    return 'stage';
  }

  return 'dev';
}
