import { Network } from '@haechi-labs/face-types';
import { isMainnet } from '@haechi-labs/shared';
import { BigNumber, ethers } from 'ethers';

import { BORA_MAINNET_AVAILABLE_SWAPS, BORA_TESTNET_AVAILABLE_SWAPS } from './db/bora';
import {
  KLAYTN_MAINNET_AVAILABLE_SWAPS,
  KLAYTN_TESTNET_AVAILABLE_SWAPS,
  KLAYTN_TESTNET_COINS,
} from './db/klaytn';
import { POLYGON_MAINNET_AVAILABLE_SWAPS, POLYGON_TESTNET_AVAILABLE_SWAPS } from './db/polygon';
import { KlayswapQuote } from './quotes/klayswap/KlayswapQuote';
import { Quote } from './quotes/Quote';
import { MockSwapQuote } from './quotes/uniswapv2/MockSwapQuote';
import { UniswapV2Quote } from './quotes/uniswapv2/UniswapV2Quote';
import { Address, AvailableSwap, Coin, coinEquals } from './types';

export class SwapModule {
  private isMock = false;

  constructor(isMock?: boolean) {
    if (isMock) {
      this.isMock = isMock;
    }
  }

  get klaytnAvailableSwaps() {
    // mainnet/testnet 구분 없이 모든 네트워크 사용
    return KLAYTN_MAINNET_AVAILABLE_SWAPS.concat(KLAYTN_TESTNET_AVAILABLE_SWAPS);
  }

  get boraAvailableSwaps() {
    return BORA_MAINNET_AVAILABLE_SWAPS.concat(BORA_TESTNET_AVAILABLE_SWAPS);
  }

  get polygonAvailableSwaps() {
    return POLYGON_MAINNET_AVAILABLE_SWAPS.concat(POLYGON_TESTNET_AVAILABLE_SWAPS);
  }

  getAllPairs(): AvailableSwap[] {
    return [
      ...this.klaytnAvailableSwaps,
      ...this.boraAvailableSwaps,
      ...this.polygonAvailableSwaps,
    ];
  }

  getSwappablePairList(): [coin1: Coin, coin2: Coin][] {
    return this.getAllPairs().map(({ coin1, coin2 }) => [coin1, coin2]);
  }

  get klaytnSwappableCoinList() {
    return this.klaytnAvailableSwaps.flatMap((swap) => [swap.coin1, swap.coin2]);
  }

  get boraSwappableCoinList() {
    return this.boraAvailableSwaps.flatMap((swap) => [swap.coin1, swap.coin2]);
  }

  get polygonSwappableCoinList() {
    return this.polygonAvailableSwaps.flatMap((swap) => [swap.coin1, swap.coin2]);
  }

  getSwappableCoinList(): Coin[] {
    const allCoins = [
      ...this.klaytnSwappableCoinList,
      ...this.boraSwappableCoinList,
      ...this.polygonSwappableCoinList,
    ];
    return uniqueCoins(allCoins);
  }

  getTargetCoins(coin: Coin): Coin[] {
    const allPairs = [
      ...this.klaytnAvailableSwaps,
      ...this.boraAvailableSwaps,
      ...this.polygonAvailableSwaps,
    ];
    const matchPairs = allPairs.filter(
      (pair) => coinEquals(pair.coin1, coin) || coinEquals(pair.coin2, coin)
    );
    return matchPairs.map((pair) => {
      if (coinEquals(pair.coin1, coin)) {
        return pair.coin2;
      } else {
        return pair.coin1;
      }
    });
  }

  async getQuote(params: {
    walletAddress: Address;
    from: Coin;
    to: Coin;
    fromAmount: BigNumber | null;
    toAmount: BigNumber | null;
    provider: ethers.providers.Provider;
  }): Promise<Quote> {
    const { walletAddress, from, to, fromAmount, toAmount, provider } = params;

    if (from.network !== to.network) {
      throw new Error(`Invalid network: from network: ${from.network}, to network: ${to.network}`);
    }

    if (this.isMock) {
      return new MockSwapQuote(
        walletAddress,
        from,
        to,
        fromAmount,
        toAmount,
        this.polygonAvailableSwaps[0]
      );
    }

    const allPairs = this.getAllPairs();

    const matchedPair = allPairs.find(
      (pair) =>
        (coinEquals(pair.coin1, from) && coinEquals(pair.coin2, to)) ||
        (coinEquals(pair.coin2, from) && coinEquals(pair.coin1, to))
    );

    if (!matchedPair) {
      throw new Error('No matched pair.');
    }

    const quoteFactory = this.buildQuoteFactory(from, to, matchedPair);

    if (!quoteFactory) {
      throw new Error('Invalid blockchain: not supported chain');
    }

    const networkType = isMainnet(from.network) ? 'mainnet' : 'testnet';
    return quoteFactory.create(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      toAmount,
      provider,
      matchedPair
    );
  }

  buildQuoteFactory(from: Coin, to: Coin, availableSwap: AvailableSwap) {
    switch (from.network) {
      case Network.POLYGON:
      case Network.AMOY:
        return UniswapV2Quote;
      case Network.KLAYTN:
      case Network.BAOBAB:
        if (
          !isMainnet(from.network) &&
          (coinEquals(from, KLAYTN_TESTNET_COINS.BORA) || coinEquals(to, KLAYTN_TESTNET_COINS.BORA))
        ) {
          return UniswapV2Quote;
        }
        return KlayswapQuote;
      case Network.BORA:
      case Network.BORA_TESTNET:
        return availableSwap.quote!;
    }
  }

  // get contract address to approve
  getSpenderContractAddress(network: Network): Address[] {
    const allPairs = this.getAllPairs();

    const routers = allPairs
      .filter((pair) => pair.coin1.network === network && pair.coin2.network === network)
      .map((pair) => pair.routerContractAddress)
      .filter((router, index, routers) => routers.indexOf(router) === index);

    return routers;
  }

  getMaxTxGasUsedFrom(coin: Coin) {
    const allPairs = [
      ...this.klaytnAvailableSwaps,
      ...this.boraAvailableSwaps,
      ...this.polygonAvailableSwaps,
    ];
    const matchPairs = allPairs.filter(
      (pair) => coinEquals(pair.coin1, coin) || coinEquals(pair.coin2, coin)
    );
    const maxSwapTxGasUsed = matchPairs.reduce((max, pair) =>
      max.swapTxGasUsed.gt(pair.swapTxGasUsed) ? max : pair
    ).swapTxGasUsed;

    return maxSwapTxGasUsed;
  }
}

function uniqueCoins(allCoins: Coin[]) {
  const result = [];
  for (const coin of allCoins) {
    if (result.findIndex((c) => coinEquals(c, coin)) === -1) {
      result.push(coin);
    }
  }
  return result;
}
