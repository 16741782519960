import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableSwap } from '../../types';

export const COINS = {
  MATIC: {
    network: Network.POLYGON,
    address: '0x0',
    name: 'MATIC',
  },
  WMATIC: {
    network: Network.POLYGON,
    address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
    name: 'WKLAY',
  },
  PBORA: {
    network: Network.POLYGON,
    address: '0x0EF39E52704Ad52E2882BBfA6781167E1b6c4510',
    name: 'PBORA',
  },
  USDC: {
    network: Network.POLYGON,
    address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359', // USDC
    name: 'USDC',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.MATIC,
    coin2: COINS.PBORA,
    routerContractAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff',
    // Previous used gas for a swap tx: 272,594
    // https://polygonscan.com/tx/0xe35739fb6db0fba9c06e49b4611e58bdd8c5f7a33b47fdb57402546000f5106e
    swapTxGasUsed: BigNumber.from(350 * 1000),
  },
  {
    coin1: COINS.USDC,
    coin2: COINS.PBORA,
    routerContractAddress: '0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff', // quickswap
    // Previous used gas for a swap tx: 272,594
    // https://polygonscan.com/tx/0xe35739fb6db0fba9c06e49b4611e58bdd8c5f7a33b47fdb57402546000f5106e
    swapTxGasUsed: BigNumber.from(350 * 1000),
  },
];
