import { TransactionReceipt, TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network, Receipt } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import {
  ERC20_APPROVE_ABI,
  UNISWAP_V2_PAIR_ABI,
  UNISWAP_V2_ROUTER_ABI,
} from '@haechi-labs/shared/constants/abi';
import { BigNumber, constants, ethers } from 'ethers';

import { APPROVE_GAS_USED } from '../../constant';
import {
  BORA_MAINNET_AVAILABLE_SWAPS,
  BORA_MAINNET_COINS,
  BORA_TESTNET_AVAILABLE_SWAPS,
  BORA_TESTNET_COINS,
} from '../../db/bora';
import {
  KLAYTN_MAINNET_AVAILABLE_SWAPS,
  KLAYTN_MAINNET_COINS,
  KLAYTN_TESTNET_AVAILABLE_SWAPS,
  KLAYTN_TESTNET_COINS,
} from '../../db/klaytn';
import {
  POLYGON_MAINNET_AVAILABLE_SWAPS,
  POLYGON_MAINNET_COINS,
  POLYGON_TESTNET_AVAILABLE_SWAPS,
  POLYGON_TESTNET_COINS,
} from '../../db/polygon';
import {
  Address,
  AvailableSwap,
  ChainNetMap,
  Coin,
  CoinAmount,
  coinEquals,
  NetworkType,
} from '../../types';
import { Quote } from '../Quote';

// https://haechilabs.slack.com/archives/C04M4Q0HVHU/p1675992277244719
const DEADLINE = 300; // 5 minutes

interface QuoteData {
  isApprovalNeeded: boolean;
  estimatedToAmount: BigNumber | null;
  estimatedFromAmount: BigNumber | null;
  swapFee: CoinAmount;
  swapTxFee: CoinAmount;
  approveTxFee: CoinAmount;
}

export class UniswapV2Quote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly provider: ethers.providers.Provider;

  readonly walletAddress: Address;
  readonly from: Coin;
  readonly to: Coin;
  readonly fromAmount: BigNumber | null;
  readonly toAmount: BigNumber | null;
  readonly pair: AvailableSwap;

  constructor(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    data: QuoteData,
    provider: ethers.providers.Provider,
    pair: AvailableSwap
  ) {
    this.networkType = networkType;
    this.data = data;
    this.walletAddress = walletAddress;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.toAmount = toAmount;
    this.provider = provider;
    this.pair = pair;
  }

  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    provider: ethers.providers.Provider,
    pair: AvailableSwap
  ) {
    // from amount or to amount
    if (fromAmount === undefined && toAmount === undefined) {
      throw new Error('fromAmount or toAmount must be defined');
    }

    const routerAddress = pair.routerContractAddress;

    let estimatedToAmount: BigNumber | null = null;
    let estimatedFromAmount: BigNumber | null = null;
    if (toAmount === null) {
      estimatedToAmount = fromAmount?.isZero()
        ? constants.Zero
        : await calculateAmountsOut(networkType, from, to, fromAmount!, provider, routerAddress);
    }
    if (fromAmount === null) {
      estimatedFromAmount = toAmount?.isZero()
        ? constants.Zero
        : await calculateAmountsIn(networkType, from, to, toAmount!, provider, routerAddress);
    }

    const [isApprovalNeeded, gasPrice] = await Promise.all([
      calculateNeedsApprove(
        networkType,
        walletAddress,
        from,
        to,
        fromAmount || maxFromAmountWithSlippage(from.network, estimatedFromAmount!),
        provider,
        routerAddress
      ),
      provider.getGasPrice(),
    ]);
    const platformCoin = getPlatformCoin(from.network);

    const quoteData = {
      isApprovalNeeded,
      estimatedFromAmount,
      estimatedToAmount,
      swapFee: {
        coin: from,
        amount: calculateFee(from.network, fromAmount || estimatedFromAmount!),
      },
      swapTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(getSwapGasUsed(networkType, from, to)),
      },
      approveTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };

    return new UniswapV2Quote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      toAmount,
      quoteData,
      provider,
      pair
    );
  }

  buildApproveTx(): TransactionRequest {
    if (!this.data.isApprovalNeeded) {
      throw new Error('approve is not needed');
    }
    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      this.pair.routerContractAddress,
      ethers.constants.MaxUint256,
    ]);
    return {
      to: this.from.address,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildSwapTx(): TransactionRequest {
    if (this.fromAmount == null && !isPlatformCoin(this.from) && !isPlatformCoin(this.to)) {
      return this.buildExactToSwapTx();
    } else if (this.toAmount == null && !isPlatformCoin(this.from) && !isPlatformCoin(this.to)) {
      return this.buildExactFromSwapTx();
    }
    if (this.fromAmount == null && isPlatformCoin(this.from)) {
      return this.buildExactToPlatformFrom();
    } else if (this.fromAmount == null && isPlatformCoin(this.to)) {
      return this.buildExactToPlatformTo();
    }
    if (this.toAmount == null && isPlatformCoin(this.from)) {
      return this.buildExactFromPlatformFrom();
    } else if (this.toAmount == null && isPlatformCoin(this.to)) {
      return this.buildExactFromPlatformTo();
    }

    throw new Error('invalid swap tx');
  }

  private buildExactToSwapTx(): TransactionRequest {
    if (this.toAmount == null) {
      throw new Error('toAmount must be defined');
    }
    if (this.data.estimatedFromAmount == null) {
      throw new Error('estimatedFromAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData('swapTokensForExactTokens', [
      this.toAmount,
      maxFromAmountWithSlippage(this.from.network, this.data.estimatedFromAmount),
      [this.from.address, this.to.address],
      this.walletAddress,
      Math.floor(Date.now() / 1000) + DEADLINE,
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  private buildExactFromSwapTx(): TransactionRequest {
    if (this.fromAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedToAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData('swapExactTokensForTokens', [
      this.fromAmount,
      minToAmountWithSlippage(this.from.network, this.data.estimatedToAmount),
      [this.from.address, this.to.address],
      this.walletAddress,
      Math.floor(Date.now() / 1000) + DEADLINE,
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  getFee(): { swapFee: CoinAmount; swapTxFee: CoinAmount; approveTxFee: CoinAmount } {
    return {
      swapFee: this.data.swapFee,
      swapTxFee: this.data.swapTxFee,
      approveTxFee: this.data.approveTxFee,
    };
  }

  getFromAndTo():
    | { from: CoinAmount; estimatedTo: CoinAmount; estimatedToWithSlippage: CoinAmount }
    | { estimatedFrom: CoinAmount; estimatedFromWithSlippage: CoinAmount; to: CoinAmount } {
    if (!this.fromAmount) {
      return {
        estimatedFrom: {
          coin: this.from,
          amount: this.data.estimatedFromAmount!,
        },
        estimatedFromWithSlippage: {
          coin: this.from,
          amount: maxFromAmountWithSlippage(this.from.network, this.data.estimatedFromAmount!),
        },
        to: {
          coin: this.to,
          amount: this.toAmount!,
        },
      };
    } else {
      return {
        from: {
          coin: this.from,
          amount: this.fromAmount,
        },
        estimatedTo: {
          coin: this.to,
          amount: this.data.estimatedToAmount!,
        },
        estimatedToWithSlippage: {
          coin: this.to,
          amount: minToAmountWithSlippage(this.from.network, this.data.estimatedToAmount!),
        },
      };
    }
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  refresh(): Promise<Quote> {
    return UniswapV2Quote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.toAmount,
      this.provider,
      this.pair
    );
  }

  private buildExactToPlatformFrom(): TransactionRequest {
    if (!isPlatformCoin(this.from)) {
      throw new Error('from must be platform coin');
    }
    if (this.toAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedFromAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData(
      this.from.network === Network.KLAYTN ? 'swapKLAYForExactTokens' : 'swapETHForExactTokens',
      [
        this.toAmount,
        [getWrappedIfPlatformCoin(this.networkType, this.from).address, this.to.address],
        this.walletAddress,
        Math.floor(Date.now() / 1000) + DEADLINE,
      ]
    );

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: maxFromAmountWithSlippage(this.from.network, this.data.estimatedFromAmount),

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  private buildExactToPlatformTo(): TransactionRequest {
    if (!isPlatformCoin(this.to)) {
      throw new Error('to must be platform coin');
    }
    if (this.toAmount == null) {
      throw new Error('toAmount must be defined');
    }
    if (this.data.estimatedFromAmount == null) {
      throw new Error('estimatedFromAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData(
      this.from.network === Network.KLAYTN ? 'swapTokensForExactKLAY' : 'swapTokensForExactETH',
      [
        this.toAmount,
        maxFromAmountWithSlippage(this.from.network, this.data.estimatedFromAmount),
        [
          getWrappedIfPlatformCoin(this.networkType, this.from).address,
          getWrappedIfPlatformCoin(this.networkType, this.to).address,
        ],
        this.walletAddress,
        Math.floor(Date.now() / 1000) + DEADLINE,
      ]
    );

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  private buildExactFromPlatformFrom(): TransactionRequest {
    if (!isPlatformCoin(this.from)) {
      throw new Error('from must be platform coin');
    }
    if (this.fromAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedToAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData(
      this.from.network === Network.KLAYTN ? 'swapExactKLAYForTokens' : 'swapExactETHForTokens',
      [
        minToAmountWithSlippage(this.from.network, this.data.estimatedToAmount),
        [getWrappedIfPlatformCoin(this.networkType, this.from).address, this.to.address],
        this.walletAddress,
        Math.floor(Date.now() / 1000) + DEADLINE,
      ]
    );

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: this.fromAmount,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  private buildExactFromPlatformTo(): TransactionRequest {
    if (!isPlatformCoin(this.to)) {
      throw new Error('to must be platform address');
    }
    if (this.fromAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedToAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const uniswapInterface = new ethers.utils.Interface(UNISWAP_V2_ROUTER_ABI);
    const functionData = uniswapInterface.encodeFunctionData(
      this.from.network === Network.KLAYTN ? 'swapExactTokensForKLAY' : 'swapExactTokensForETH',
      [
        this.fromAmount,
        minToAmountWithSlippage(this.from.network, this.data.estimatedToAmount),
        [this.from.address, getWrappedIfPlatformCoin(this.networkType, this.to).address],
        this.walletAddress,
        Math.floor(Date.now() / 1000) + DEADLINE,
      ]
    );

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  parseReceipt(receipt: Receipt): {
    fromAmount: CoinAmount;
    toAmount: CoinAmount;
    swapFee: CoinAmount;
  } {
    const swapIface = new ethers.utils.Interface(UNISWAP_V2_PAIR_ABI);
    const ethersReceipt = receipt.internal as TransactionReceipt;
    for (const log of ethersReceipt.logs) {
      let parsedLog;
      try {
        parsedLog = swapIface.parseLog(log);
      } catch (e) {
        // pass unknown event log
        continue;
      }
      if (!parsedLog) {
        continue;
      }
      if (parsedLog.name !== 'Swap') {
        continue;
      }
      const fromAmount = BigNumber.from(parsedLog.args.amount0In).add(
        BigNumber.from(parsedLog.args.amount1In)
      );
      return {
        fromAmount: {
          coin: this.from,
          amount: fromAmount,
        },
        toAmount: {
          coin: this.to,
          amount: BigNumber.from(parsedLog.args.amount0Out).add(
            BigNumber.from(parsedLog.args.amount1Out)
          ),
        },
        swapFee: {
          coin: this.from,
          amount: calculateFee(this.from.network, fromAmount),
        },
      };
    }
    throw new Error('Failed to parse uniswap v2 events');
  }
}

async function calculateNeedsApprove(
  networkType: NetworkType,
  walletAddress: Address,
  from: Coin,
  to: Coin,
  fromAmount: BigNumber,
  provider: ethers.providers.Provider,
  routerAddress: string
) {
  if (isPlatformCoin(from)) {
    return false;
  }

  const contract = new ethers.Contract(from.address, ERC20_APPROVE_ABI, provider);
  let allowance: BigNumber | number = await contract.allowance(walletAddress, routerAddress);

  if (typeof allowance === 'number') {
    allowance = ethers.BigNumber.from(allowance);
  }

  // 같을 때도 approve를 해야함
  const isApprovalNeeded = allowance.lte(fromAmount);
  return isApprovalNeeded;
}

function getSwapGasUsed(networkType: NetworkType, coin1: Coin, coin2: Coin): BigNumber {
  const pairMap: ChainNetMap<AvailableSwap[]> = {
    [Blockchain.KLAYTN]: {
      mainnet: KLAYTN_MAINNET_AVAILABLE_SWAPS,
      testnet: KLAYTN_TESTNET_AVAILABLE_SWAPS,
    },
    [Blockchain.BORA]: {
      mainnet: BORA_MAINNET_AVAILABLE_SWAPS,
      testnet: BORA_TESTNET_AVAILABLE_SWAPS,
    },
    [Blockchain.POLYGON]: {
      mainnet: POLYGON_MAINNET_AVAILABLE_SWAPS,
      testnet: POLYGON_TESTNET_AVAILABLE_SWAPS,
    },
  };

  const availableSwaps: AvailableSwap[] | undefined =
    pairMap[networkToBlockchain(coin1.network)]?.[networkType];
  if (!availableSwaps) {
    throw new Error(`invalid network: ${networkType} and coin1.network:${coin1.network}`);
  }

  for (const availableSwap of availableSwaps) {
    if (coinEquals(availableSwap.coin1, coin1) && coinEquals(availableSwap.coin2, coin2)) {
      return availableSwap.swapTxGasUsed;
    }
    if (coinEquals(availableSwap.coin1, coin2) && coinEquals(availableSwap.coin2, coin1)) {
      return availableSwap.swapTxGasUsed;
    }
  }
  throw new Error(`swap not found ${coin1}, ${coin2}`);
}

async function calculateAmountsIn(
  networkType: NetworkType,
  from: Coin,
  to: Coin,
  toAmount: BigNumber,
  provider: ethers.providers.Provider,
  routerAddress: string
) {
  const contract = new ethers.Contract(routerAddress, UNISWAP_V2_ROUTER_ABI, provider);
  const amountIn: (number | BigNumber)[] = await contract.getAmountsIn(toAmount, [
    getWrappedIfPlatformCoin(networkType, from).address,
    getWrappedIfPlatformCoin(networkType, to).address,
  ]);

  return numberToBigNumber(amountIn[0]);
}

async function calculateAmountsOut(
  networkType: NetworkType,
  from: Coin,
  to: Coin,
  fromAmount: BigNumber,
  provider: ethers.providers.Provider,
  routerAddress: string
) {
  const contract = new ethers.Contract(routerAddress, UNISWAP_V2_ROUTER_ABI, provider);
  const amountOut: (number | BigNumber)[] = await contract.getAmountsOut(fromAmount, [
    getWrappedIfPlatformCoin(networkType, from).address,
    getWrappedIfPlatformCoin(networkType, to).address,
  ]);

  return numberToBigNumber(amountOut[1]);
}

function numberToBigNumber(input: number | BigNumber) {
  if (typeof input === 'number') {
    return ethers.BigNumber.from(input);
  }
  return input;
}

// bora portal의 컨트랙트는 3% slippage가 기본값
// claim swap은 0.5% slippage가 기본값 (정확히는 klay swap이 0.5, claim swap은 0.1)
// quick swap은 0.5% slippage가 기본값
// https://haechilabs.slack.com/archives/C04M4Q0HVHU/p1675994254215769
const SLIPPAGE_BORA = 300; // 300 basis point = 3%
const SLIPPAGE_KLAYTN = 50; // 50 basis point = 0.5%
const SLIPPAGE_POLYGON = 50;
function maxFromAmountWithSlippage(network: Network, estimatedFromAmount: BigNumber) {
  let slippage = 0;
  const blockchain = networkToBlockchain(network);
  switch (blockchain) {
    case Blockchain.BORA:
      slippage = SLIPPAGE_BORA;
      break;
    case Blockchain.KLAYTN:
      slippage = SLIPPAGE_KLAYTN;
      break;
    case Blockchain.POLYGON:
      slippage = SLIPPAGE_POLYGON;
      break;
    default:
      throw new Error(`Invalid network: ${network}`);
  }
  return estimatedFromAmount.mul(10000 + slippage).div(10000);
}

function minToAmountWithSlippage(network: Network, estimatedToAmount: BigNumber) {
  let slippage = 0;
  const blockchain = networkToBlockchain(network);
  switch (blockchain) {
    case Blockchain.BORA:
      slippage = SLIPPAGE_BORA;
      break;
    case Blockchain.KLAYTN:
      slippage = SLIPPAGE_KLAYTN;
      break;
    case Blockchain.POLYGON:
      slippage = SLIPPAGE_POLYGON;
      break;
    default:
      throw new Error(`Invalid network: ${network}`);
  }
  return estimatedToAmount.mul(10000 - slippage).div(10000);
}

function getWrappedIfPlatformCoin(networkType: NetworkType, coin: Coin): Coin {
  if (isPlatformCoin(coin)) {
    const wrappedMap: ChainNetMap<Coin> = {
      [Blockchain.KLAYTN]: {
        mainnet: KLAYTN_MAINNET_COINS.WKLAY,
        testnet: KLAYTN_TESTNET_COINS.WKLAY,
      },
      [Blockchain.POLYGON]: {
        mainnet: POLYGON_MAINNET_COINS.WMATIC,
        testnet: POLYGON_TESTNET_COINS.WMATIC,
      },
      [Blockchain.BORA]: {
        mainnet: BORA_MAINNET_COINS.WBORA,
        testnet: BORA_TESTNET_COINS.WBORA,
      },
    };

    const wrappedCoin = wrappedMap[networkToBlockchain(coin.network)]?.[networkType];

    if (!wrappedCoin) {
      throw new Error("The blockchain doesn't support getWrappedIfPlatformCoin");
    }

    return wrappedCoin;
  }

  return coin;
}

function isPlatformCoin(coin: Coin) {
  return coin.address === '0x0';
}

function getPlatformCoin(network: Network): Coin {
  return {
    network,
    address: '0x0',
  };
}

function calculateFee(network: Network, amount: BigNumber) {
  if (networkToBlockchain(network) === Blockchain.BORA) {
    // 보라 portal UI에서 확인
    return amount.mul(1).div(100);
  } else if (networkToBlockchain(network) === Blockchain.KLAYTN) {
    // uniswap v2 기본 수수료 0.3%
    return amount.mul(3).div(1000);
  } else if (networkToBlockchain(network) === Blockchain.POLYGON) {
    // uniswap v2 기본 수수료 0.3%
    return amount.mul(3).div(1000);
  }
  throw new Error('Invalid network: ' + network);
}
