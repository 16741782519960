import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Network } from '@haechi-labs/face-types';
import { BigNumber, ethers } from 'ethers';

import { Quote, SerializedQuote } from '../Quote';
import { Address, Coin, CoinAmount, ConvertStatus } from '../types';

export class BoraMockQuote implements Quote {
  public readonly walletAddress: Address;
  public readonly fromAmount: BigNumber;
  public readonly from: Coin;
  public readonly to: Coin;

  constructor(walletAddress: Address, from: Coin, to: Coin, fromAmount: BigNumber) {
    this.walletAddress = walletAddress;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
  }

  buildApproveTx(): TransactionRequest {
    return {
      to: this.walletAddress,
      from: this.walletAddress,
      value: 1,

      nonce: undefined,
      gasLimit: undefined,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildConvertTx(): TransactionRequest {
    return {
      to: this.walletAddress,
      from: this.walletAddress,
      value: 1,

      nonce: undefined,
      gasLimit: undefined,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  async checkStatus(): Promise<ConvertStatus> {
    return {
      status: 'FINISHED',
      toChain: null,
    };
  }

  getFee(): {
    convertFee: CoinAmount;
    convertTxFee: CoinAmount;
    approveTxFee: CoinAmount;
  } {
    return {
      convertFee: {
        coin: this.from,
        amount: ethers.utils.parseEther('0.01'),
      },
      convertTxFee: {
        coin: {
          network: this.from.network,
          address: '0x0',
        },
        amount: ethers.utils.parseEther('0.01'),
      },
      approveTxFee: {
        coin: {
          network: this.from.network,
          address: '0x0',
        },
        amount: ethers.utils.parseEther('0.01'),
      },
    };
  }

  getTargetAmount(): BigNumber {
    return this.fromAmount;
  }

  isApprovalNeeded(): boolean {
    return true;
  }

  refresh(): Promise<Quote> {
    return Promise.resolve(this);
  }

  async serialize(): Promise<SerializedQuote> {
    return {
      fromBlockchainNetwork: Network.ETHEREUM,
      toBlockchainNetwork: Network.BORA,
      fromCoinAddress: '',
      toCoinAddress: '',
      fromAmount: '',
      toAmount: '',
      fromAddress: '',
      toAddress: '',
      type: '',
      toBlockchainRecentBlockNumber: 0,
    };
  }

  getConvertingTime() {
    return '1';
  }
}
