import { Blockchain, Network } from '@haechi-labs/face-types';

import {
  BLOCKCHAIN_INFO_MAP,
  ethlikeBlockchains,
  mainnetList,
  networkConfigs,
  SupportPlatform,
} from '../blockchains/blockchainConfigs';

export const isMainnet = (network: Network) => {
  return mainnetList.includes(network);
};

export const isSupportedNetwork = (network: Network, platforms: SupportPlatform[]) => {
  const supportedNetworks = networkConfigs.reduce((acc, cur) => {
    if (platforms.some((platform) => cur.isSupported.includes(platform))) {
      acc.push(cur.network);
    }
    return acc;
  }, [] as Network[]);
  return supportedNetworks.includes(network);
};

export const networkToBlockchain = (network: Network): Blockchain => {
  if (!network) throw new Error(`networkToBlockchain() network: ${network}`);
  const networkLowerCase = network?.toLowerCase();
  const config = networkConfigs.find((config) => config.network === networkLowerCase);
  if (!config) throw new Error(`Unsupported network: ${networkLowerCase}`);
  return config.blockchain;
};

export const getPlatFormCoinDecimalByBlockchain = (blockchain: Blockchain) => {
  if (Object.prototype.hasOwnProperty.call(BLOCKCHAIN_INFO_MAP, blockchain)) {
    return BLOCKCHAIN_INFO_MAP[blockchain].decimal;
  }
  throw new Error('unsupported blockchain');
};

export const isEthlikeNetwork = (network: Network) => {
  return ethlikeBlockchains.includes(networkToBlockchain(network));
};

export const isEthlikeBlockchain = (blockchain: Blockchain) => {
  return ethlikeBlockchains.includes(blockchain);
};

export const getTestnetNetworkFromBlockchain = (blockchain: Blockchain) => {
  const network = networkConfigs.find(
    (config) => !config.isMainnet && config.blockchain === blockchain
  )?.network;
  if (!network) throw new Error('Unsupported blockchain ' + blockchain);
  return network;
};

export const getMainnetNetworkFromBlockchain = (blockchain: Blockchain) => {
  const network = networkConfigs.find(
    (config) => config.isMainnet && config.blockchain === blockchain
  )?.network;
  if (!network) throw new Error('Unsupported blockchain ' + blockchain);
  return network;
};

export const getChainId = (network: Network) => {
  const id = networkConfigs.find((config) => config.network === network)?.chainId;
  if (!id) throw new Error('Unsupported network ' + network);
  return id;
};

export const getNetworkByChainId = (chainId: number) => {
  const network = networkConfigs.find((config) => config.chainId === chainId)?.network;
  if (!network) throw new Error('Unsupported network ' + network);
  return network;
};
