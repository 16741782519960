import { TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import { BORA_CONVERT_ABI, ERC20_APPROVE_ABI } from '@haechi-labs/shared/constants/abi';
import { BigNumber, constants, ethers } from 'ethers';

import { APPROVE_GAS_USED } from '../../constant';
import { Quote, SerializedQuote } from '../../Quote';
import {
  Address,
  AvailableConvert,
  Coin,
  CoinAmount,
  ConvertStatus,
  ConvertType,
  NetworkType,
} from '../../types';
import { checkTokenApproval, getPlatformCoin } from '../../utils';

interface QuoteData {
  isApprovalNeeded: boolean;
  toBlockchainRecentBlockNumber: number;
  convertFee: CoinAmount;
  convertTxFee: CoinAmount;
  approveTxFee: CoinAmount;
}

/**
 * tBora(Bora) <-> Bora(Klaytn)
 */
export class BoraPortalQuote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly fromProvider: ethers.providers.Provider;
  private readonly toProvider: ethers.providers.Provider;

  public readonly walletAddress: Address;
  public readonly fromAmount: BigNumber;
  public readonly from: Coin;
  public readonly to: Coin;
  public readonly pair: AvailableConvert;

  private constructor(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    data: QuoteData,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ) {
    this.networkType = networkType;
    this.data = data;
    this.walletAddress = walletAddress;
    this.fromProvider = fromProvider;
    this.toProvider = toProvider;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.pair = pair;
  }

  /**
   * Quote 객체를 만들고 필요한 정보를 얻어옴
   */
  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const [isApprovalNeeded, toBlockchainRecentBlockNumber, convertFeeAmount, gasPrice] =
      await Promise.all([
        checkTokenApproval(
          from.address,
          walletAddress,
          pair.convertContractAddress,
          fromAmount,
          fromProvider
        ),
        toProvider.getBlockNumber(),
        this.calculateFee({
          convertContractAddress: pair.convertContractAddress,
          fromAddress: from.address,
          fromProvider,
        }),
        fromProvider.getGasPrice(),
      ]);

    const quoteData = {
      isApprovalNeeded,
      toBlockchainRecentBlockNumber,
      convertFee: {
        coin: from,
        amount: convertFeeAmount,
      },
      convertTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(pair.gasUsed),
      },
      approveTxFee: {
        coin: getPlatformCoin(from.network),
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };

    return new BoraPortalQuote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  buildApproveTx(): TransactionRequest {
    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      this.pair.convertContractAddress,
      ethers.constants.MaxUint256,
    ]);
    return {
      to: this.from.address,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  /**
   * from blockchain 의 walletAddress에서 to blockchain의 walletAddress로
   */
  buildConvertTx(): TransactionRequest {
    if (this.fromAmount.lt(this.data.convertFee.amount)) {
      throw new Error('fee is larger than from amount');
    }
    const convertInterface = new ethers.utils.Interface(BORA_CONVERT_ABI);
    const functionData = convertInterface.encodeFunctionData('requestERC20Transfer', [
      this.from.address,
      this.walletAddress,
      // 보라 convert의 경우 from에 fee가 포함 안되어있음. Face에서는 from에 fee가 들어있음
      this.fromAmount.sub(this.data.convertFee.amount),
      this.data.convertFee.amount,
      [],
    ]);
    return {
      to: this.pair.convertContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: this.pair.gasUsed,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  async checkStatus(convertTxHash: string): Promise<ConvertStatus> {
    const toContract = new ethers.Contract(
      this.pair.toBlockchainContractAddress,
      BORA_CONVERT_ABI,
      this.toProvider
    );
    const args = {
      requestTxHash: null,
      tokenType: null,
      from: this.walletAddress,
      to: this.walletAddress,
      tokenAddress: this.to.address,
      valueOrTokenId: null,
      handleNonce: null,
      lowerHandleNonce: null,
      extraData: null,
    };
    const filter = toContract.filters.HandleValueTransfer(
      args.requestTxHash,
      args.tokenType,
      args.from,
      args.to,
      args.tokenAddress,
      args.valueOrTokenId,
      args.handleNonce,
      args.lowerHandleNonce,
      args.extraData
    );

    let events = await toContract.queryFilter(filter, this.data.toBlockchainRecentBlockNumber);
    events = events.filter(
      (ev) => ev.args?.requestTxHash.toLowerCase() === convertTxHash.toLowerCase()
    );
    if (events.length > 0) {
      return {
        status: 'FINISHED',
        toChain: {
          txHash: events[0].transactionHash,
          blockNumber: events[0].blockNumber,
          blockHash: events[0].blockHash,
        },
      };
    } else {
      const fromReceipt = await this.fromProvider.getTransactionReceipt(convertTxHash);
      if (fromReceipt?.status === 0) {
        return {
          status: 'FAILED',
          toChain: null,
        };
      }

      return {
        status: 'TO_CHAIN_PENDING',
        toChain: null,
      };
    }
  }

  getFee(): { convertFee: CoinAmount; approveTxFee: CoinAmount; convertTxFee: CoinAmount } {
    return {
      convertFee: this.data.convertFee,
      approveTxFee: this.data.approveTxFee,
      convertTxFee: this.data.convertTxFee,
    };
  }

  getTargetAmount(): BigNumber {
    return this.fromAmount.sub(this.data.convertFee.amount);
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  refresh(): Promise<Quote> {
    return BoraPortalQuote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.fromProvider,
      this.toProvider,
      this.pair
    );
  }

  async serialize(): Promise<SerializedQuote> {
    const latestBlockNumber = await this.toProvider.getBlock('latest');
    return {
      fromBlockchainNetwork: this.from.network,
      toBlockchainNetwork: this.to.network,
      fromCoinAddress: this.from.address,
      toCoinAddress: this.to.address,
      fromAmount: this.fromAmount.toHexString(),
      toAmount: this.getTargetAmount().toHexString(),
      fromAddress: this.walletAddress,
      toAddress: this.walletAddress,
      type: ConvertType.BORA_PORTAL, // this may vary by platform. If new type implemented, fix this too.
      toBlockchainRecentBlockNumber: latestBlockNumber.number,
    };
  }

  static async deserialize(
    data: SerializedQuote,
    networkType: NetworkType,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    pair: AvailableConvert
  ): Promise<Quote> {
    const fromNetwork = data.fromBlockchainNetwork.toUpperCase() as Network;
    const toNetwork = data.toBlockchainNetwork.toUpperCase() as Network;
    const fromBlockchain = networkToBlockchain(fromNetwork);
    const toBlockchain = networkToBlockchain(toNetwork);

    if (
      ![Blockchain.BORA, Blockchain.KLAYTN].includes(fromBlockchain) ||
      ![Blockchain.BORA, Blockchain.KLAYTN].includes(toBlockchain)
    ) {
      throw new Error('invalid blockchain');
    }

    const fromAmount = ethers.BigNumber.from(data.fromAmount);

    const fromCoin = {
      network: fromNetwork,
      address: data.fromCoinAddress,
    };
    const toCoin = {
      network: toNetwork,
      address: data.toCoinAddress,
    };

    const quoteData = {
      isApprovalNeeded: false,
      toBlockchainRecentBlockNumber: data.toBlockchainRecentBlockNumber,
      convertFee: {
        coin: fromCoin,
        amount: constants.Zero,
      },
      convertTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
      approveTxFee: {
        coin: getPlatformCoin(fromCoin.network),
        amount: constants.Zero,
      },
    };
    return new BoraPortalQuote(
      networkType,
      data.fromAddress,
      fromCoin,
      toCoin,
      fromAmount,
      quoteData,
      fromProvider,
      toProvider,
      pair
    );
  }

  static async calculateFee(data: {
    convertContractAddress: Address;
    fromAddress: Address;
    fromProvider: ethers.providers.Provider;
  }): Promise<BigNumber> {
    const contract = new ethers.Contract(
      data.convertContractAddress,
      BORA_CONVERT_ABI,
      data.fromProvider
    );
    const feeOfERC20: BigNumber | number = await contract.feeOfERC20(data.fromAddress);

    if (typeof feeOfERC20 === 'number') {
      return ethers.BigNumber.from(feeOfERC20);
    }
    return feeOfERC20;
  }

  getConvertingTime() {
    return '1';
  }
}
