import { TransactionRequest } from '@ethersproject/abstract-provider';
// import { Receipt } from '@haechi-labs/face-types';
import { BigNumber, ethers } from 'ethers';

import { AvailableSwap, Coin, CoinAmount } from '../../types';
import { Quote } from '../Quote';

export class MockSwapQuote implements Quote {
  readonly walletAddress: string;
  readonly from: Coin;
  readonly to: Coin;
  readonly fromAmount: BigNumber | null;
  readonly toAmount: BigNumber | null;
  readonly pair: AvailableSwap;

  constructor(
    walletAddress: string,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    pair: AvailableSwap
  ) {
    this.walletAddress = walletAddress;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.toAmount = toAmount;
    this.pair = pair;
  }

  buildApproveTx(): TransactionRequest {
    return {
      to: this.walletAddress,
      from: this.walletAddress,
      value: 1,

      nonce: undefined,
      gasLimit: undefined,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildSwapTx(): TransactionRequest {
    return {
      to: this.walletAddress,
      from: this.walletAddress,
      value: 1,

      nonce: undefined,
      gasLimit: undefined,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  getFee(): { swapFee: CoinAmount; swapTxFee: CoinAmount; approveTxFee: CoinAmount } {
    return {
      swapFee: {
        coin: {
          network: this.from.network,
          address: '0x0',
        },
        amount: ethers.utils.parseUnits('1', 'finney'),
      },
      approveTxFee: {
        coin: {
          network: this.from.network,
          address: '0x0',
        },
        amount: ethers.utils.parseUnits('0.01', 'finney'),
      },
      swapTxFee: {
        coin: {
          network: this.from.network,
          address: '0x0',
        },
        amount: ethers.utils.parseUnits('0.01', 'finney'),
      },
    };
  }

  getFromAndTo():
    | { from: CoinAmount; estimatedTo: CoinAmount; estimatedToWithSlippage: CoinAmount }
    | { estimatedFrom: CoinAmount; estimatedFromWithSlippage: CoinAmount; to: CoinAmount } {
    if (this.fromAmount) {
      return {
        from: {
          coin: this.from,
          amount: ethers.utils.parseUnits('1', 18),
        },
        estimatedTo: {
          coin: this.to,
          amount: ethers.utils.parseUnits('1', 18),
        },
        estimatedToWithSlippage: {
          coin: this.to,
          amount: ethers.utils.parseUnits('0.95', 18),
        },
      };
    } else {
      return {
        to: {
          coin: this.from,
          amount: ethers.utils.parseUnits('1', 18),
        },
        estimatedFrom: {
          coin: this.to,
          amount: ethers.utils.parseUnits('1', 18),
        },
        estimatedFromWithSlippage: {
          coin: this.to,
          amount: ethers.utils.parseUnits('0.95', 18),
        },
      };
    }
  }

  isApprovalNeeded(): boolean {
    return true;
  }

  refresh(): Promise<Quote> {
    return Promise.resolve(this);
  }

  parseReceipt(/* receipt: Receipt */): {
    fromAmount: CoinAmount;
    toAmount: CoinAmount;
    swapFee: CoinAmount;
  } {
    return {
      fromAmount: {
        coin: this.from,
        amount: this.fromAmount ?? this.toAmount!,
      },
      toAmount: {
        coin: this.to,
        amount: this.fromAmount ?? this.toAmount!,
      },
      swapFee: {
        coin: this.from,
        // FIXME use constant
        amount: (this.fromAmount ?? this.toAmount!).mul(3).div(1000),
      },
    };
  }
}
