import { TransactionReceipt, TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network, Receipt } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import {
  BORA_TBORA_SWAP_EVENT_ABI,
  BORA_TO_TBORA_SWAP_ABI,
  ERC20_APPROVE_ABI,
  TBORA_TO_BORA_SWAP_ABI,
} from '@haechi-labs/shared/constants/abi';
import { BigNumber, ethers } from 'ethers';

import { APPROVE_GAS_USED } from '../../constant';
import { Address, AvailableSwap, Coin, CoinAmount, NetworkType } from '../../types';
import { Quote, QuoteData } from '../Quote';

/**
 * bora 네트워크에서 bora <> tbora swap
 * 수수료 없음
 */
export class BoraTBoraQuote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly provider: ethers.providers.Provider;

  readonly walletAddress: string;
  readonly from: Coin;
  readonly to: Coin;
  readonly fromAmount: BigNumber | null;
  readonly toAmount: BigNumber | null;
  readonly pair: AvailableSwap;

  constructor(
    networkType: NetworkType,
    walletAddress: string,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    pair: AvailableSwap,
    data: QuoteData,
    provider: ethers.providers.Provider
  ) {
    this.networkType = networkType;
    this.walletAddress = walletAddress;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.toAmount = toAmount;
    this.pair = pair;
    this.data = data;
    this.provider = provider;
  }

  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    provider: ethers.providers.Provider,
    pair: AvailableSwap
  ) {
    if (fromAmount == null && toAmount == null) {
      throw new Error('fromAmount or toAmount must be provided');
    }

    if (networkToBlockchain(from.network) !== Blockchain.BORA) {
      throw new Error('from coin must be from Bora network');
    }
    if (networkToBlockchain(to.network) !== Blockchain.BORA) {
      throw new Error('to coin must be from Bora network');
    }

    let estimatedToAmount: BigNumber | null = null;
    let estimatedFromAmount: BigNumber | null = null;
    if (toAmount == null) {
      estimatedToAmount = fromAmount;
    }
    if (fromAmount == null) {
      estimatedFromAmount = toAmount;
    }

    const [isApprovalNeeded, gasPrice] = await Promise.all([
      calculateNeedsApprove(
        networkType,
        walletAddress,
        from,
        to,
        fromAmount ?? estimatedFromAmount ?? BigNumber.from(0),
        provider,
        pair.routerContractAddress
      ),
      provider.getGasPrice(),
    ]);

    const platformCoin = getPlatformCoin(from.network);

    const quoteData = {
      isApprovalNeeded,
      estimatedFromAmount,
      estimatedToAmount,
      swapFee: {
        coin: from,
        amount: BigNumber.from(0),
      },
      swapTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(pair.swapTxGasUsed),
      },
      approveTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };
    return new BoraTBoraQuote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      toAmount,
      pair,
      quoteData,
      provider
    );
  }

  getFromAndTo():
    | { from: CoinAmount; estimatedTo: CoinAmount; estimatedToWithSlippage: CoinAmount }
    | { estimatedFrom: CoinAmount; estimatedFromWithSlippage: CoinAmount; to: CoinAmount } {
    if (this.fromAmount == null) {
      return {
        estimatedFrom: {
          coin: this.from,
          amount: this.toAmount!,
        },
        estimatedFromWithSlippage: {
          coin: this.from,
          amount: this.toAmount!,
        },
        to: {
          coin: this.to,
          amount: this.toAmount!,
        },
      };
    } else {
      return {
        from: {
          coin: this.from,
          amount: this.fromAmount!,
        },
        estimatedTo: {
          coin: this.to,
          amount: this.fromAmount!,
        },
        estimatedToWithSlippage: {
          coin: this.to,
          amount: this.fromAmount!,
        },
      };
    }
  }

  getFee(): { swapFee: CoinAmount; swapTxFee: CoinAmount; approveTxFee: CoinAmount } {
    return {
      swapFee: this.data.swapFee,
      swapTxFee: this.data.swapTxFee,
      approveTxFee: this.data.approveTxFee,
    };
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  buildApproveTx(): TransactionRequest {
    if (!this.data.isApprovalNeeded) {
      throw new Error('Approval is not needed');
    }

    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      this.pair.routerContractAddress,
      ethers.constants.MaxUint256,
    ]);
    return {
      to: this.from.address,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildSwapTx(): TransactionRequest {
    if (isPlatformCoin(this.from)) {
      // bora -> tbora
      return this.buildBoraToTBoraSwapTx();
    } else {
      return this.buildTBoraToBoarSwapTx();
    }
  }

  refresh(): Promise<Quote> {
    return BoraTBoraQuote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.toAmount,
      this.provider,
      this.pair
    );
  }

  parseReceipt(receipt: Receipt): {
    fromAmount: CoinAmount;
    toAmount: CoinAmount;
    swapFee: CoinAmount;
  } {
    const swapEventIface = new ethers.utils.Interface(BORA_TBORA_SWAP_EVENT_ABI);
    const ethersReceipt = receipt.internal as TransactionReceipt;
    for (const log of ethersReceipt.logs) {
      let parsedLog;
      try {
        parsedLog = swapEventIface.parseLog(log);
      } catch (e) {
        // pass unknown event log
        continue;
      }
      if (!parsedLog) {
        continue;
      }
      if (parsedLog.name === 'Deposit' || parsedLog.name === 'Withdraw') {
        return {
          fromAmount: {
            coin: this.from,
            amount: parsedLog.args.amount,
          },
          toAmount: {
            coin: this.to,
            amount: parsedLog.args.amount,
          },
          swapFee: {
            coin: this.from,
            amount: BigNumber.from(0),
          },
        };
      }
    }
    throw new Error('No swap event log found');
  }

  private buildBoraToTBoraSwapTx(): TransactionRequest {
    const depositInterface = new ethers.utils.Interface(BORA_TO_TBORA_SWAP_ABI);
    const functionData = depositInterface.encodeFunctionData('deposit', []);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: this.fromAmount ?? this.data.estimatedFromAmount!,

      nonce: undefined,
      gasLimit: this.pair.swapTxGasUsed,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  private buildTBoraToBoarSwapTx(): TransactionRequest {
    const withdrawalInterface = new ethers.utils.Interface(TBORA_TO_BORA_SWAP_ABI);
    const functionData = withdrawalInterface.encodeFunctionData('withdraw', [this.fromAmount]);
    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: this.pair.swapTxGasUsed,
      gasPrice: undefined,
      chainId: undefined,
    };
  }
}

async function calculateNeedsApprove(
  networkType: NetworkType,
  walletAddress: Address,
  from: Coin,
  to: Coin,
  fromAmount: BigNumber,
  provider: ethers.providers.Provider,
  routerContractAddress: string
) {
  if (isPlatformCoin(from)) {
    return false;
  }

  const contract = new ethers.Contract(from.address, ERC20_APPROVE_ABI, provider);
  let allowance: BigNumber | number = await contract.allowance(
    walletAddress,
    routerContractAddress
  );
  if (typeof allowance === 'number') {
    allowance = BigNumber.from(allowance);
  }

  const isApprovalNeeded = allowance.lt(fromAmount);
  return isApprovalNeeded;
}

const platformAddress = '0x0000000000000000000000000000000000000000';
function isPlatformCoin(coin: Coin): boolean {
  return coin.address === platformAddress || coin.address === '0x0';
}

function getPlatformCoin(network: Network): Coin {
  return {
    network,
    address: platformAddress,
  };
}
