import { Network } from '@haechi-labs/face-types';
import { ERC20_APPROVE_ABI } from '@haechi-labs/shared/constants/abi';
import { BigNumber, ethers } from 'ethers';

import { Address, Coin } from './types';

export async function checkTokenApproval(
  tokenAddress: Address,
  walletAddress: Address,
  spenderAddress: Address,
  amount: BigNumber,
  provider: ethers.providers.Provider
): Promise<boolean> {
  // 보라 3.0으로 바뀌면서 Convert 할때 네이티브 코인이면 approve 필요없고, erc20이면 allowance 확인 후 approve 필요함
  if (isPlatformCoinAddress(tokenAddress)) {
    // 플랫폼 코인(네이티브 토큰)은 approve가 필요 없음
    return false;
  }
  const contract = new ethers.Contract(tokenAddress, ERC20_APPROVE_ABI, provider);
  let allowance: BigNumber | number = await contract.allowance(walletAddress, spenderAddress);

  if (typeof allowance === 'number') {
    allowance = ethers.BigNumber.from(allowance);
  }

  // 같을 때도 approve를 해야함
  return allowance.lte(amount);
}

export function getPlatformCoin(network: Network): Coin {
  return {
    network,
    address: '0x0',
  };
}

export function isPlatformCoinAddress(address: string) {
  const platformAddress = '0x0000000000000000000000000000000000000000';
  return address === platformAddress || address === '0x0';
}
