import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableSwap } from '../../types';

export const COINS = {
  MATIC: {
    network: Network.AMOY,
    address: '0x0',
    name: 'MATIC',
  },
  WMATIC: {
    network: Network.AMOY,
    address: '0x9c3C9283D3e44854697Cd22D3Faa240Cfb032889',
    name: 'WMATIC',
  },
  PBORA: {
    network: Network.AMOY,
    address: '0xD5887FDC3B3327dDc1fe4716dC11dF8124B96b95',
    name: 'PBORA',
  },
  USDC: {
    network: Network.AMOY,
    address: '0xd8842657297361Bf6D5Cb1039a812A7f8EF46D5e',
    name: 'USDC',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.MATIC,
    coin2: COINS.PBORA,
    routerContractAddress: '0x8954AfA98594b838bda56FE4C12a09D7739D179b',
    // Previous used gas for a swap tx: 138,200
    // https://mumbai.polygonscan.com/tx/0x773cf6ace9e953b3eca5fbf18686424be6981db80cb968a3c95a009faddcfccf
    swapTxGasUsed: BigNumber.from(200 * 1000),
  },
  {
    coin1: COINS.USDC,
    coin2: COINS.PBORA,
    routerContractAddress: '0xFFD339A005498A262c02D6Ee060716f3FA44C4fB',
    // Previous used gas for a swap tx: 149,976
    // https://mumbai.polygonscan.com/tx/0x24b66c2e7e5aac8e7465ca59261ccf2215df1c9c9aab3a0a015666029851d0c5
    swapTxGasUsed: BigNumber.from(200 * 1000),
  },
];

export const FACTORY_CONTRACT_ADDRESS = '0x5757371414417b8C6CAad45bAeF941aBc7d3Ab32';
