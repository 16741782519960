import { Blockchain, Network } from '@haechi-labs/face-types';
import { BigNumber, ethers } from 'ethers';

import { Quote } from './quotes/Quote';

export type NetworkType = 'mainnet' | 'testnet';

export type Address = string;

export type Coin = {
  network: Network;
  address: Address;
  // for debugging purpose
  name?: string;
};

export type CoinAmount = {
  coin: Coin;
  amount: BigNumber;
};

export type TxFee = {
  gasUsed: BigNumber;
  fee: CoinAmount;
};

export function coinEquals(coin1: Coin, coin2: Coin): boolean {
  return (
    coin1.network === coin2.network && coin1.address.toLowerCase() === coin2.address.toLowerCase()
  );
}

export type AvailableSwap = {
  coin1: Coin;
  coin2: Coin;
  pairContractAddress?: string;
  routerContractAddress: string;
  swapTxGasUsed: BigNumber;
  quote?: {
    create(
      networkType: NetworkType,
      walletAddress: Address,
      from: Coin,
      to: Coin,
      fromAmount: BigNumber | null,
      toAmount: BigNumber | null,
      provider: ethers.providers.Provider,
      pair: AvailableSwap
    ): Promise<Quote>;
  };
};

export type ChainNetMap<T> = {
  [key1 in Blockchain]?: {
    [key2 in NetworkType]: T;
  };
};
