import { Network } from '@haechi-labs/face-types';
import { isMainnet } from '@haechi-labs/shared';
import { BigNumber, ethers } from 'ethers';

import { MAINNET_AVAILABLE_CONVERTS, TESTNET_AVAILABLE_CONVERTS } from './bora';
import { BoraMockQuote } from './bora/mockQuote';
import { BoraPortalQuote } from './bora/quotes/BoraPortalQuote';
import { BoraPortalV3Quote } from './bora/quotes/BoraPortalV3Quote';
import { PurpleBridgeQuote } from './bora/quotes/PurpleBridgeQuote';
import { Quote, SerializedQuote } from './Quote';
import { Address, AvailableConvert, Coin, coinEquals, ConvertType } from './types';

export class ConvertModule {
  // from network 에 접근하는 provider
  private isMock = false;

  constructor(isMock?: boolean) {
    if (isMock) {
      this.isMock = isMock;
    }
  }

  private get availableConverts(): AvailableConvert[] {
    // mainnet/testnet 구분 없이 모든 네트워크 사용
    return MAINNET_AVAILABLE_CONVERTS.concat(TESTNET_AVAILABLE_CONVERTS);
  }

  // convert 가 가능한 코인 목록을 얻어오기
  getConvertibleCoinList(): Coin[] {
    return this.availableConverts
      .map(({ from }) => from)
      .filter((v, i, arr) => arr.indexOf(v) === i); // 중복 코인 제거
  }

  // convert 가 가능한 페어 목록을 얻어오기
  getConvertiblePairList(): [from: Coin, to: Coin][] {
    return this.availableConverts.map(({ from, to }) => [from, to]);
  }

  // coin을 하나 받아서 convert가 가능한 Network목록 얻어오기
  getTargetNetworks(coin: Coin): Network[] {
    const list = this.availableConverts
      .filter(
        ({ from }) =>
          from.address.toLowerCase() === coin.address.toLowerCase() && from.network === coin.network
      )
      .map(({ to }) => to.network);

    return Array.from(new Set<Network>(list).values());
  }

  // coin을 하나 받아서 convert가 가능한 목록 얻어오기
  getTargetCoins(coin: Coin): Coin[] {
    return this.availableConverts
      .filter(
        ({ from }) =>
          from.address.toLowerCase() === coin.address.toLowerCase() && from.network === coin.network
      )
      .map(({ to }) => to);
  }

  // convert tx에 관한 정보는 quote를 통해 얻어온다
  async getQuote(options: {
    walletAddress: Address;
    from: Coin;
    to: Coin;
    fromAmount: BigNumber;
    fromProvider: ethers.providers.Provider;
    toProvider: ethers.providers.Provider;
  }): Promise<Quote> {
    const { walletAddress, from, to, fromAmount, fromProvider, toProvider } = options;

    if (this.isMock) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(new BoraMockQuote(walletAddress, from, to, fromAmount));
        }, 1000);
      });
    }

    const matchedPair = this.availableConverts.find(
      (pair) => coinEquals(pair.from, from) && coinEquals(pair.to, to)
    );

    if (!matchedPair) {
      throw new Error('No matched pair.');
    }

    const networkType = isMainnet(from.network) ? 'mainnet' : 'testnet';
    return matchedPair.quote.create(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      fromProvider,
      toProvider,
      matchedPair
    );
  }

  async deserializeQuote(
    data: SerializedQuote,
    fromProvider: ethers.providers.Provider,
    toProvider: ethers.providers.Provider,
    fromNetwork: Network
  ): Promise<Quote> {
    const matchedPair = this.availableConverts.find(
      (pair) =>
        pair.from.address.toLowerCase() === data.fromCoinAddress.toLowerCase() &&
        pair.to.address.toLowerCase() === data.toCoinAddress.toLowerCase() &&
        pair.from.network.toLowerCase() === data.fromBlockchainNetwork.toLowerCase() &&
        pair.to.network.toLowerCase() === data.toBlockchainNetwork.toLowerCase()
    );

    if (!matchedPair) {
      throw new Error('No matched pair.');
    }
    const networkType = isMainnet(fromNetwork) ? 'mainnet' : 'testnet';
    switch (data.type) {
      // @deprecated: BORA_CONNECT will be deprecated.
      case 'BORA_CONVERT':
      case ConvertType.BORA_PORTAL:
        return await BoraPortalQuote.deserialize(
          data,
          networkType,
          fromProvider,
          toProvider,
          matchedPair
        );
      case ConvertType.BORA_PORTAL_V3:
        return await BoraPortalV3Quote.deserialize(
          data,
          networkType,
          fromProvider,
          toProvider,
          matchedPair
        );
      case ConvertType.PURPLE_BRIDGE:
        return await PurpleBridgeQuote.deserialize(
          data,
          networkType,
          fromProvider,
          toProvider,
          matchedPair
        );
    }

    throw new Error('Invalid quote type');
  }

  // get contract address to approve
  getSpenderContractAddress(network: Network): Address[] {
    return this.availableConverts
      .filter(({ from }) => from.network === network)
      .map(({ convertContractAddress }) => convertContractAddress);
  }
}
