import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableConvert } from '../types';
import { BoraPortalV3Quote } from './quotes/BoraPortalV3Quote';

const COINS = {
  klaytnbora: {
    network: Network.KLAYTN,
    address: '0x02cbe46fb8a1f579254a9b485788f2d86cad51aa',
    name: 'BORA',
  },
  pbora: {
    network: Network.POLYGON,
    address: '0x0EF39E52704Ad52E2882BBfA6781167E1b6c4510',
    name: 'PBORA',
  },
  borabora: {
    network: Network.BORA,
    address: '0x0',
    name: 'BORA',
  },
};

// https://boraportal.notion.site/3-0-aeda92f81d1d47c1a6fe78ae7005d293
export const AVAILABLE_CONVERTS: AvailableConvert[] = [
  {
    from: COINS.klaytnbora,
    to: COINS.borabora,
    convertContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    toBlockchainContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
  {
    from: COINS.borabora,
    to: COINS.klaytnbora,
    convertContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    toBlockchainContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
  {
    from: COINS.pbora,
    to: COINS.borabora,
    convertContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    toBlockchainContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
  {
    from: COINS.borabora,
    to: COINS.pbora,
    convertContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    toBlockchainContractAddress: '0x1446ca1b07da7de53e990fc298b50cc1b52609ba',
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
];
