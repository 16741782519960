import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { AvailableConvert } from '../types';
import { BoraPortalV3Quote } from './quotes/BoraPortalV3Quote';
// import { PurpleBridgeQuote } from './quotes/PurpleBridgeQuote';

const COINS = {
  bora_b: {
    network: Network.BORA_TESTNET,
    address: '0x0', // 네이티브 토큰
    name: 'BORA',
  },
  bora_k: {
    network: Network.BAOBAB,
    address: '0x19e822d63d2f6a7fdf0f9644471903b09585f51a',
    name: 'BORA',
  },
  pbora: {
    network: Network.AMOY,
    address: '0x7e08f02de8fd971e73c27f9ef4b71b2e7ec16f93',
    name: 'PBORA',
  },
  // TODO: tbora -> BORA(b) 스왑 추가 예정
  // tbora: {
  //   network: Network.BORA_TESTNET,
  //   address: '0x3d5cb6Be01f218CCA1Ec077028F2CFDC943A36f6',
  //   name: 'TBORA',
  // },
};

export const AVAILABLE_CONVERTS: AvailableConvert[] = [
  // BORA 3.0 BORA(k) <-> BORA(b)
  {
    from: COINS.bora_k,
    to: COINS.bora_b,
    convertContractAddress: '0xdf08f314A1447c28B7FCE7F5A15163F5Dc042E07', // Klaytn Bridge Router
    toBlockchainContractAddress: '0xe27fFC2972EE7F9BaA863D0b7aCa8f2243703D2d', // Bora Bridge Router
    // TODO: 가스비 테스트 필요. 이전에 테스트한 tx가 ? gas를 사용함
    // https://baobab.scope.klaytn.com/tx/
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
  {
    from: COINS.bora_b,
    to: COINS.bora_k,
    convertContractAddress: '0xe27fFC2972EE7F9BaA863D0b7aCa8f2243703D2d', // Bora Bridge Router
    toBlockchainContractAddress: '0xdf08f314A1447c28B7FCE7F5A15163F5Dc042E07', // Klaytn Bridge Router
    // 이전에 테스트한 tx가 249,891 gas를 사용함
    // https://scope.boraportal.cc/tx/0xc05bebba95e9ca1dd2a83dbcb064283a4479fbf1e3c47c97389cf2b799e672e6
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },

  // BORA 3.0 pBORA <-> BORA(b)
  {
    from: COINS.pbora,
    to: COINS.bora_b,
    convertContractAddress: '0x57daae66a53e0e347033248d857c6f8bcebd4885', // Polygon Bridge Router
    toBlockchainContractAddress: '0xe27fFC2972EE7F9BaA863D0b7aCa8f2243703D2d', // Bora Bridge Router
    // TODO: 가스비 테스트 필요. 이전에 테스트한 tx가 ? gas를 사용함
    // https://mumbai.polygonscan.com/tx/
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },
  {
    from: COINS.bora_b,
    to: COINS.pbora,
    convertContractAddress: '0xe27fFC2972EE7F9BaA863D0b7aCa8f2243703D2d', // Bora Bridge Router
    toBlockchainContractAddress: '0x57daae66a53e0e347033248d857c6f8bcebd4885', // Polygon Bridge Router
    // TODO: 가스비 테스트 필요. 이전에 테스트한 tx가 ? gas를 사용함
    // https://scope.boraportal.cc/tx/
    gasUsed: BigNumber.from(300 * 1000),
    quote: BoraPortalV3Quote,
  },

  // TODO: BORA 3.0 에서 tBORA -> BORA(b) 스왑 추가 예정. tBORA 컨버트 삭제하기
  // tBORA(Bora) <-> BORA(Klaytn)
  // {
  //   from: COINS.tbora,
  //   to: COINS.bora_k,
  //   convertContractAddress: '0x1208536a1a0a8476a12cAcb1c904fD00Ab5B5A70',
  //   toBlockchainContractAddress: '0xaE4520aF8Db6e95A4a8F51A3A7374027caf22709',
  //   // 이전에 테스트한 tx가 155,247 gas를 사용함
  //   // https://scope.boraportal.com/tx/0xf51f02c5cc26ed4487fc48fdfe9bf23881e604c61a674f3dac00f41480867d5d
  //   gasUsed: BigNumber.from(200 * 1000),
  //   quote: BoraPortalV3Quote,
  // },
  // {
  //   from: COINS.bora_k,
  //   to: COINS.tbora,
  //   convertContractAddress: '0xaE4520aF8Db6e95A4a8F51A3A7374027caf22709',
  //   toBlockchainContractAddress: '0x1208536a1a0a8476a12cAcb1c904fD00Ab5B5A70',
  //   // 이전에 테스트한 tx가 94,362 gas를 사용함
  //   // https://baobab.scope.klaytn.com/tx/0xf6f3c19ed2fa893c3bf016f7fd9ac949a664305cc6f0998611a384fc7d338d7c?tabId=tokenTransfer
  //   gasUsed: BigNumber.from(150 * 1000),
  //   quote: BoraPortalV3Quote,
  // },

  // tBORA(Bora) <-> PBORA(Polygon)
  // {
  //   from: COINS.tbora,
  //   to: COINS.pbora,
  //   convertContractAddress: '0x23c28CEE5b5aB749f8d1c1E61a13818BB3BE4d0B',
  //   toBlockchainContractAddress: '0x14decc1b4967bf81a42452dc7d83881f2b3f9f5a', // MelterContractAddress
  //   // 이전에 테스트한 tx가 229,856 gas를 사용함
  //   // https://scope.boraportal.com/tx/0x5527e4d792c7be310e028955a86e57c259de6b867e1f6a36ec0e82ff47f77496
  //   gasUsed: BigNumber.from(330 * 1000),
  //   quote: PurpleBridgeQuote,
  // },
  // {
  //   from: COINS.pbora,
  //   to: COINS.tbora,
  //   convertContractAddress: '0x4E923bd7CdCB39972d3b03E523C22af37d0E8522',
  //   toBlockchainContractAddress: '0x606A54108BBA41C66A1e0887937023EcEf447AFC', // VaultContractAddress
  //   // 이전에 테스트한 tx가 311,158 gas를 사용함
  //   // https://mumbai.polygonscan.com/tx/0x547647fe56435bf07396fcee66b40bc9a12295aff94533100dac5227c3ed9b7b
  //   gasUsed: BigNumber.from(410 * 1000),
  //   quote: PurpleBridgeQuote,
  // },
];
