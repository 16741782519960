import { TransactionReceipt, TransactionRequest } from '@ethersproject/abstract-provider';
import { Blockchain, Network, Receipt } from '@haechi-labs/face-types';
import { networkToBlockchain } from '@haechi-labs/shared';
import {
  ERC20_APPROVE_ABI,
  KLAYSWAP_EVENT_ABI,
  KLAYSWAP_KLAY_BORA_LP_ABI,
  KLAYSWAP_ROUTER_ABI,
} from '@haechi-labs/shared/constants/abi';
import { BigNumber, constants, ethers } from 'ethers';

import { APPROVE_GAS_USED } from '../../constant';
import { KLAYTN_MAINNET_AVAILABLE_SWAPS, KLAYTN_TESTNET_AVAILABLE_SWAPS } from '../../db/klaytn';
import { Address, AvailableSwap, Coin, CoinAmount, coinEquals, NetworkType } from '../../types';
import { Quote, QuoteData } from '../Quote';

const SLIPPAGE_KLAYTN = 50; // 50 basis point = 0.5%
const klayswapInterface = new ethers.utils.Interface(KLAYSWAP_ROUTER_ABI);
const platformAddress = '0x0000000000000000000000000000000000000000';

export class KlayswapQuote implements Quote {
  private readonly networkType: NetworkType;
  private readonly data: QuoteData;
  private readonly provider: ethers.providers.Provider;

  readonly walletAddress: Address;
  readonly from: Coin;
  readonly to: Coin;
  readonly fromAmount: BigNumber | null;
  readonly toAmount: BigNumber | null;
  readonly pair: AvailableSwap;

  constructor(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    data: QuoteData,
    provider: ethers.providers.Provider,
    pair: AvailableSwap
  ) {
    this.networkType = networkType;
    this.data = data;
    this.walletAddress = walletAddress;
    this.from = from;
    this.to = to;
    this.fromAmount = fromAmount;
    this.toAmount = toAmount;
    this.provider = provider;
    this.pair = pair;
  }

  static async create(
    networkType: NetworkType,
    walletAddress: Address,
    from: Coin,
    to: Coin,
    fromAmount: BigNumber | null,
    toAmount: BigNumber | null,
    provider: ethers.providers.Provider,
    pair: AvailableSwap
  ) {
    // from amount or to amount
    if (fromAmount === undefined && toAmount === undefined) {
      throw new Error('fromAmount or toAmount must be defined');
    }

    if (
      networkToBlockchain(from.network) !== Blockchain.KLAYTN ||
      networkToBlockchain(to.network) !== Blockchain.KLAYTN
    ) {
      throw new Error(`invalid network: ${from.network}, ${to.network}`);
    }

    let estimatedToAmount: BigNumber | null = null;
    let estimatedFromAmount: BigNumber | null = null;
    if (toAmount === null) {
      estimatedToAmount = fromAmount?.isZero()
        ? constants.Zero
        : await calculateAmountsOut(networkType, from, to, fromAmount!, provider);
    }
    if (fromAmount === null) {
      estimatedFromAmount = toAmount?.isZero()
        ? constants.Zero
        : await calculateAmountsIn(networkType, from, to, toAmount!, provider);
    }

    const [isApprovalNeeded, gasPrice] = await Promise.all([
      calculateNeedsApprove(
        networkType,
        walletAddress,
        from,
        to,
        fromAmount || maxFromAmountWithSlippage(estimatedFromAmount!),
        provider,
        pair.routerContractAddress
      ),
      provider.getGasPrice(),
    ]);
    const platformCoin = getPlatformCoin(from.network);

    const quoteData = {
      isApprovalNeeded,
      estimatedFromAmount,
      estimatedToAmount,
      swapFee: {
        coin: from,
        amount: calculateFee(fromAmount || estimatedFromAmount!),
      },
      swapTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(getSwapGasUsed(networkType, from, to)),
      },
      approveTxFee: {
        coin: platformCoin,
        amount: gasPrice.mul(APPROVE_GAS_USED),
      },
    };

    return new KlayswapQuote(
      networkType,
      walletAddress,
      from,
      to,
      fromAmount,
      toAmount,
      quoteData,
      provider,
      pair
    );
  }

  buildApproveTx(): TransactionRequest {
    if (!this.data.isApprovalNeeded) {
      throw new Error('approve is not needed');
    }
    const erc20Interface = new ethers.utils.Interface(ERC20_APPROVE_ABI);
    const functionData = erc20Interface.encodeFunctionData('approve', [
      this.pair.routerContractAddress,
      ethers.constants.MaxUint256,
    ]);
    return {
      to: this.from.address === '0x0' ? platformAddress : this.from.address,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: APPROVE_GAS_USED,
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildSwapTx(): TransactionRequest {
    // 지불 금액 설정 케이스
    if (this.fromAmount != null) {
      if (isPlatformCoin(this.from)) {
        //  KLAY -> KIP7
        //  exchangeKlayPos
        return this.buildExchangeKlayPos();
      } else if (isPlatformCoin(this.to)) {
        //  KIP7 -> KLAY
        //  KIP7 -> KIP7
        //  exchangeKctPos
        return this.buildExchangeKctPos();
      }
    }
    // 받을 금액 설정 케이스
    if (this.toAmount != null) {
      if (isPlatformCoin(this.from)) {
        //  KLAY -> KIP7
        //  exchangeKlayNeg
        return this.buildExchangeKlayNeg();
      } else if (isPlatformCoin(this.to)) {
        //  KIP7 -> KLAY
        //  KIP7 -> KIP7
        //  exchangeKctNeg
        return this.buildExchangeKctNeg();
      }
    }
    throw new Error('invalid swap tx');
  }

  getFee(): { swapFee: CoinAmount; swapTxFee: CoinAmount; approveTxFee: CoinAmount } {
    return {
      swapFee: this.data.swapFee,
      swapTxFee: this.data.swapTxFee,
      approveTxFee: this.data.approveTxFee,
    };
  }

  getFromAndTo():
    | { from: CoinAmount; estimatedTo: CoinAmount; estimatedToWithSlippage: CoinAmount }
    | { estimatedFrom: CoinAmount; estimatedFromWithSlippage: CoinAmount; to: CoinAmount } {
    if (!this.fromAmount) {
      return {
        estimatedFrom: {
          coin: this.from,
          amount: this.data.estimatedFromAmount!,
        },
        estimatedFromWithSlippage: {
          coin: this.from,
          amount: maxFromAmountWithSlippage(this.data.estimatedFromAmount!),
        },
        to: {
          coin: this.to,
          amount: this.toAmount!,
        },
      };
    } else {
      return {
        from: {
          coin: this.from,
          amount: this.fromAmount,
        },
        estimatedTo: {
          coin: this.to,
          amount: this.data.estimatedToAmount!,
        },
        estimatedToWithSlippage: {
          coin: this.to,
          amount: minToAmountWithSlippage(this.data.estimatedToAmount!),
        },
      };
    }
  }

  isApprovalNeeded(): boolean {
    return this.data.isApprovalNeeded;
  }

  refresh(): Promise<Quote> {
    return KlayswapQuote.create(
      this.networkType,
      this.walletAddress,
      this.from,
      this.to,
      this.fromAmount,
      this.toAmount,
      this.provider,
      this.pair
    );
  }

  parseReceipt(receipt: Receipt): {
    fromAmount: CoinAmount;
    toAmount: CoinAmount;
    swapFee: CoinAmount;
  } {
    const klayswapEventInterface = new ethers.utils.Interface(KLAYSWAP_EVENT_ABI);
    const ethersReceipt = receipt.internal as TransactionReceipt;
    for (const log of ethersReceipt.logs) {
      let parsedLog;
      try {
        parsedLog = klayswapEventInterface.parseLog(log);
      } catch (e) {
        // pass unknown event log
        continue;
      }
      if (!parsedLog) {
        continue;
      }
      if (parsedLog.name !== 'ExchangePos' && parsedLog.name !== 'ExchangeNeg') {
        continue;
      }
      const fromAmount = BigNumber.from(parsedLog.args.amountA);
      return {
        fromAmount: {
          coin: this.from,
          amount: fromAmount,
        },
        toAmount: {
          coin: this.to,
          amount: BigNumber.from(parsedLog.args.amountB),
        },
        swapFee: {
          coin: this.from,
          amount: calculateFee(fromAmount),
        },
      };
    }
    throw new Error('Failed to parse klayswap events');
  }

  buildExchangeKlayPos(): TransactionRequest {
    if (this.from.address !== platformAddress && this.from.address !== '0x0') {
      throw new Error('from must be platform coin');
    }
    if (this.fromAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedToAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const functionData = klayswapInterface.encodeFunctionData('exchangeKlayPos', [
      this.to.address,
      minToAmountWithSlippage(this.data.estimatedToAmount),
      [],
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: this.fromAmount,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildExchangeKctPos(): TransactionRequest {
    if (this.fromAmount == null) {
      throw new Error('fromAmount must be defined');
    }
    if (this.data.estimatedToAmount == null) {
      throw new Error('estimatedToAmount must be defined');
    }
    const functionData = klayswapInterface.encodeFunctionData('exchangeKctPos', [
      this.from.address,
      this.fromAmount,
      this.to.address === '0x0' ? platformAddress : this.to.address,
      minToAmountWithSlippage(this.data.estimatedToAmount),
      [],
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildExchangeKlayNeg(): TransactionRequest {
    if (this.from.address !== platformAddress && this.from.address !== '0x0') {
      throw new Error('from must be platform coin');
    }
    if (this.toAmount == null) {
      throw new Error('toAmount must be defined');
    }
    if (this.data.estimatedFromAmount == null) {
      throw new Error('estimatedFromAmount must be defined');
    }

    const functionData = klayswapInterface.encodeFunctionData('exchangeKlayNeg', [
      this.to.address,
      this.toAmount,
      [],
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: maxFromAmountWithSlippage(this.data.estimatedFromAmount),

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }

  buildExchangeKctNeg(): TransactionRequest {
    if (this.toAmount == null) {
      throw new Error('toAmount must be defined');
    }
    if (this.data.estimatedFromAmount == null) {
      throw new Error('estimatedFromAmount must be defined');
    }
    const functionData = klayswapInterface.encodeFunctionData('exchangeKctNeg', [
      this.from.address,
      maxFromAmountWithSlippage(this.data.estimatedFromAmount),
      this.to.address === '0x0' ? platformAddress : this.to.address,
      this.toAmount,
      [],
    ]);

    return {
      to: this.pair.routerContractAddress,
      from: this.walletAddress,
      data: functionData,
      value: 0,

      nonce: undefined,
      gasLimit: getSwapGasUsed(this.networkType, this.from, this.to),
      gasPrice: undefined,
      chainId: undefined,
    };
  }
}

async function calculateNeedsApprove(
  networkType: NetworkType,
  walletAddress: Address,
  from: Coin,
  to: Coin,
  fromAmount: BigNumber,
  provider: ethers.providers.Provider,
  routerAddress: string
) {
  if (isPlatformCoin(from)) {
    return false;
  }

  const contract = new ethers.Contract(from.address, ERC20_APPROVE_ABI, provider);
  let allowance: BigNumber | number = await contract.allowance(walletAddress, routerAddress);

  if (typeof allowance === 'number') {
    allowance = ethers.BigNumber.from(allowance);
  }

  // 같을 때도 approve를 해야함
  const isApprovalNeeded = allowance.lte(fromAmount);
  return isApprovalNeeded;
}

function getSwapGasUsed(networkType: NetworkType, coin1: Coin, coin2: Coin): BigNumber {
  const swapTxGasUsed = getSwapLP(networkType, coin1, coin2)?.swapTxGasUsed;
  if (!swapTxGasUsed) {
    throw new Error(`swap not found ${coin1}, ${coin2}`);
  }

  return swapTxGasUsed;
}

function getSwapLP(networkType: NetworkType, coin1: Coin, coin2: Coin) {
  let availableSwaps: AvailableSwap[];
  if (networkType === 'mainnet') {
    availableSwaps = KLAYTN_MAINNET_AVAILABLE_SWAPS;
  } else if (networkType === 'testnet') {
    availableSwaps = KLAYTN_TESTNET_AVAILABLE_SWAPS;
  } else {
    throw new Error(`invalid networkType(${networkType})`);
  }

  for (const availableSwap of availableSwaps) {
    if (coinEquals(availableSwap.coin1, coin1) && coinEquals(availableSwap.coin2, coin2)) {
      return availableSwap;
    }
    if (coinEquals(availableSwap.coin1, coin2) && coinEquals(availableSwap.coin2, coin1)) {
      return availableSwap;
    }
  }
}

async function calculateAmountsIn(
  networkType: NetworkType,
  from: Coin,
  to: Coin,
  toAmount: BigNumber,
  provider: ethers.providers.Provider
) {
  const routerAddress = getSwapLP(networkType, from, to)?.pairContractAddress;
  if (!routerAddress) {
    throw new Error(`does not exist swap LP`);
  }

  const contract = new ethers.Contract(routerAddress, KLAYSWAP_KLAY_BORA_LP_ABI, provider);
  const amountIn: number | BigNumber = await contract.estimateNeg(
    to.address === '0x0' ? platformAddress : to.address,
    toAmount
  );
  return numberToBigNumber(amountIn);
}

async function calculateAmountsOut(
  networkType: NetworkType,
  from: Coin,
  to: Coin,
  fromAmount: BigNumber,
  provider: ethers.providers.Provider
) {
  const routerAddress = getSwapLP(networkType, from, to)?.pairContractAddress;
  if (!routerAddress) {
    throw new Error(`does not exist swap LP`);
  }

  const contract = new ethers.Contract(routerAddress, KLAYSWAP_KLAY_BORA_LP_ABI, provider);
  const amountOut: number | BigNumber = await contract.estimatePos(
    from.address === '0x0' ? platformAddress : from.address,
    fromAmount
  );

  return numberToBigNumber(amountOut);
}

function numberToBigNumber(input: number | BigNumber) {
  if (typeof input === 'number') {
    return ethers.BigNumber.from(input);
  }
  return input;
}

function maxFromAmountWithSlippage(estimatedFromAmount: BigNumber) {
  return estimatedFromAmount.mul(10000 + SLIPPAGE_KLAYTN).div(10000);
}

function minToAmountWithSlippage(estimatedToAmount: BigNumber) {
  return estimatedToAmount.mul(10000 - SLIPPAGE_KLAYTN).div(10000);
}

function isPlatformCoin(coin: Coin) {
  return coin.address === platformAddress || coin.address === '0x0';
}

function getPlatformCoin(network: Network): Coin {
  return {
    network,
    address: platformAddress,
  };
}

function calculateFee(amount: BigNumber) {
  // klayswap 수수료 0.3% (UI로 확인)
  return amount.mul(3).div(1000);
}
