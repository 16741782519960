import { Network } from '@haechi-labs/face-types';
import { BigNumber } from 'ethers';

import { BoraTBoraQuote } from '../../quotes/boratbora/BoraTBoraQuote';
import { UniswapV2Quote } from '../../quotes/uniswapv2/UniswapV2Quote';
import { AvailableSwap } from '../../types';

export const COINS = {
  TBORA: {
    network: Network.BORA_TESTNET,
    address: '0x3d5cb6Be01f218CCA1Ec077028F2CFDC943A36f6',
    name: 'TBORA',
  },
  BID: {
    network: Network.BORA_TESTNET,
    address: '0xB77207d7718B29aa653f935Af55D9B2a61abF891',
    name: 'BID',
  },
  BSLT: {
    network: Network.BORA_TESTNET,
    address: '0x5479F499ff06150df2F4Db0C8f3532828aecf704',
    name: 'BSLT',
  },
  BORA: {
    network: Network.BORA_TESTNET,
    address: '0x0',
    name: 'BORA',
  },
  WBORA: {
    network: Network.BORA_TESTNET,
    address: '0x3157bb18287834bc880412845a063d05c60ab755',
    name: 'WBORA',
  },
  // bora testnet 테스트용 토큰
  AVCD: {
    network: Network.BORA_TESTNET,
    address: '0x44f58b1e8a9849e4a7f8db709519b4d9fd41b0e2',
    name: 'AVCD',
  },
  BNN: {
    network: Network.BORA_TESTNET,
    address: '0x297d2cd4d66a8e0bb404a5137e5b8ba89d8e2416',
    name: 'BNN',
  },
  CHRY: {
    network: Network.BORA_TESTNET,
    address: '0x409feb078989029333c689c60c08eb08ac8110bd',
    name: 'CHRY',
  },
};

export const AVAILABLE_SWAPS: AvailableSwap[] = [
  {
    coin1: COINS.TBORA,
    coin2: COINS.BORA,
    routerContractAddress: '0x07192546B50a3B754a746ec2c9f17bfB494fA02A',
    // TODO: 다미 데이터. 실제 측정 필요.
    swapTxGasUsed: BigNumber.from(200 * 1000),
    quote: BoraTBoraQuote,
  },
  {
    coin1: COINS.TBORA,
    coin2: COINS.BID,
    routerContractAddress: '0xE3c61904CEa97a96108d1C009393C18B7b0D55c8',
    // Previous used gas for a swap tx: 157,792
    // https://scope.boraportal.com/tx/0x510939ae2dee64a63e6d50a176baa2f88b160dd2e62cb23e2df4a0b1542c6161
    swapTxGasUsed: BigNumber.from(200 * 1000),
    quote: UniswapV2Quote,
  },
  {
    coin1: COINS.TBORA,
    coin2: COINS.BSLT,
    routerContractAddress: '0xE3c61904CEa97a96108d1C009393C18B7b0D55c8',
    // Previous used gas for a swap tx: 157,792
    // https://scope.boraportal.com/tx/0x510939ae2dee64a63e6d50a176baa2f88b160dd2e62cb23e2df4a0b1542c6161
    swapTxGasUsed: BigNumber.from(200 * 1000),
    quote: UniswapV2Quote,
  },
  {
    coin1: COINS.BORA,
    coin2: COINS.AVCD,
    // https://scope.boraportal.cc/tx/0xd3a5d00a96a8c3cb079b613aed4b4222a1fdad587be57870b6616f49fafc649d?tab=token-transfers
    routerContractAddress: '0xD5851579c9894D5b73A355D7AE1395D8754F65a5',
    swapTxGasUsed: BigNumber.from(500 * 1000),
    quote: UniswapV2Quote,
  },
  {
    coin1: COINS.BORA,
    coin2: COINS.BNN,
    routerContractAddress: '0xD5851579c9894D5b73A355D7AE1395D8754F65a5',
    // https://scope.boraportal.cc/tx/0xd3a5d00a96a8c3cb079b613aed4b4222a1fdad587be57870b6616f49fafc649d?tab=token-transfers
    swapTxGasUsed: BigNumber.from(500 * 1000),
    quote: UniswapV2Quote,
  },
  {
    coin1: COINS.BORA,
    coin2: COINS.CHRY,
    routerContractAddress: '0xD5851579c9894D5b73A355D7AE1395D8754F65a5',
    // https://scope.boraportal.cc/tx/0xd3a5d00a96a8c3cb079b613aed4b4222a1fdad587be57870b6616f49fafc649d?tab=token-transfers
    swapTxGasUsed: BigNumber.from(500 * 1000),
    quote: UniswapV2Quote,
  },
];
