import { ethers } from 'ethers';

import bora_bridge_abi from './bora_bridge_abi.json';

export const ERC20_TRANSFER_ABI = [
  'function transfer(address to, uint256 value) public returns (bool success)',
];
export const ERC20_APPROVE_ABI = [
  'function allowance(address owner, address spender) view returns (uint256)',
  'function approve(address spender, uint256 value) returns (bool)',
];
export const ERC721_TRANSFER_ABI = [
  'function transferFrom(address from, address to, uint256 tokenId)',
  'function safeTransferFrom(address from, address to, uint256 tokenId)',
  'function safeTransferFrom(address from, address to, uint256 tokenId, bytes data)',
];
export const ERC1155_TRANSFER_ABI = [
  'function safeTransferFrom(address from, address to, uint256 tokenId, uint256 amount, bytes data)',
];

// BORA 2.0
export const BORA_CONVERT_ABI = [
  'function requestERC20Transfer(address _tokenAddress,address _to,uint256 _value,uint256 _feeLimit,bytes memory _extraData)',
  'function feeOfERC20(address _tokenAddress) view returns (uint256)',
  'event HandleValueTransfer(bytes32 requestTxHash,uint8 tokenType,address indexed from,address indexed to,address indexed tokenAddress,uint256 valueOrTokenId,uint64 handleNonce,uint64 lowerHandleNonce,bytes extraData)',
];

// BORA 3.0
export const BORA3_CONVERT_ABI = new ethers.utils.Interface(bora_bridge_abi.abi);

export const PURPLE_BRIDGE_ABI = [
  'function lockAndMint(bytes destUserBytes, uint256 amount)',
  'function burnAndRelease(bytes destUserBytes, uint256 amount)',

  // Bora -> Polygon
  'event Lock(bytes32 indexed uniqueKey, address indexed srcUser, uint256 indexed destChainId, bytes destUserBytes, uint256 amount, uint256 charge)',
  'event Mint(bytes32 indexed uniqueKey, uint256 indexed srcChainId, address indexed destUser, uint256 amount, uint256 fee)',

  // Polygon -> Bora
  'event Burn(bytes32 indexed uniqueKey, address indexed srcUser, uint256 indexed destChainId, bytes destUserBytes, uint256 amount, uint256 charge)',
  'event Release(bytes32 indexed uniqueKey, uint256 indexed srcChainId, address indexed destUser, uint256 amount, uint256 fee)',
];

export const UNISWAP_V2_ROUTER_ABI = [
  'function getAmountsOut(uint amountIn, address[] memory path) public view returns (uint[] memory amounts)',
  'function getAmountsIn(uint amountOut, address[] memory path) public view returns (uint[] memory amounts)',
  'function swapExactTokensForTokens(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
  'function swapTokensForExactTokens(uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',

  'function swapExactETHForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
  'function swapTokensForExactETH(uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
  'function swapExactTokensForETH(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
  'function swapETHForExactTokens(uint amountOut, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
  'function addLiquidity(address tokenA, address tokenB, uint amountADesired, uint amountBDesired, uint amountAMin, uint amountBMin, address to, uint deadline) external returns (uint amountA, uint amountB, uint liquidity)',

  // For Klaytn
  'function swapExactKLAYForTokens(uint amountOutMin, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
  'function swapTokensForExactKLAY(uint amountOut, uint amountInMax, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
  'function swapExactTokensForKLAY(uint amountIn, uint amountOutMin, address[] calldata path, address to, uint deadline) external returns (uint[] memory amounts)',
  'function swapKLAYForExactTokens(uint amountOut, address[] calldata path, address to, uint deadline) external payable returns (uint[] memory amounts)',
];

export const UNISWAP_V2_PAIR_ABI = [
  'event Swap(address indexed sender,uint amount0In,uint amount1In,uint amount0Out,uint amount1Out,address indexed to)',
];

export const UNISWAP_V2_FACTORY_ABI = [
  'function createPair(address tokenA, address tokenB) external returns (address pair)',
  'function getPair(address tokenA, address tokenB) external view returns (address pair)',
];

export const KLAYSWAP_ROUTER_ABI = [
  'function exchangeKlayPos(address token, uint256 amount, address[] memory path) external payable',
  'function exchangeKctPos(address tokenA, uint256 amountA, address tokenB, uint256 amountB, address[] memory path) external',
  'function exchangeKlayNeg(address token, uint256 amount, address[] memory path) external payable',
  'function exchangeKctNeg(address tokenA, uint256 amountA, address tokenB, uint256 amountB, address[] memory path) external',
  'function tokenToPool(address tokenA, address tokenB) external view returns (address)',
  'function poolExist(address pool) external view returns (bool)',
];

export const KLAYSWAP_KLAY_BORA_LP_ABI = [
  'function estimatePos(address token, uint amount) public view returns (uint)',
  'function estimateNeg(address token, uint amount) public view returns (uint)',
];

export const KLAYSWAP_EVENT_ABI = [
  'event ExchangePos(address tokenA, uint amountA, address tokenB, uint amountB)',
  'event ExchangeNeg(address tokenA, uint amountA, address tokenB, uint amountB)',
];

export const BORA_TO_TBORA_SWAP_ABI = ['function deposit() public payable'];

export const TBORA_TO_BORA_SWAP_ABI = ['function withdraw(uint256 amount) public'];

export const BORA_TBORA_SWAP_EVENT_ABI = [
  'event Deposit(address dst, uint256 amount)',
  'event Withdrawal(address src, uint256 amount)',
];
